<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>Manage Order - Process Pickup Note - {{ pickup.pickupNo }}</h3>
          <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                <label for="code">Enter authorization code</label>
                <input type="text" id="code" class="form-control" v-model="code">
                </div>
            </div>
            <div class="col-md-9" style="padding-top: 25px;">
                <button v-if="!isVerified" @click="verifyOrder" class="btn btn-primary">Verify</button>
                <button v-else class="btn btn-success">Verified</button>
            </div>
          </div>
          <div :style="{ 'display': displayOrder }" style="margin-top:30px;">
            <div class="form">
              <div class="row">
                <strong>1. Requesters Details</strong>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Name</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.User?.name">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Position</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.User?.designation">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Date Requested</label>
                    <input type="text" readonly id="address" class="form-control" :value="formatDate(pickup.createdAt,'true')" >
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Purpose</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.Purpose?.purpose">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Project/Site</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.project">
                  </div>
                </div>
              </div>
            </div>

            <div class="form">
              <div class="row">
                <strong>2. Pickup Instructions</strong>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Warehouse</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.Warehouse?.name">
                  </div>
                </div>
                <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Scheduled Pickup?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="isScheduled">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Pickup Date</label>
                    <input v-if="pickup.scheduled == true" type="text" readonly id="address" class="form-control" :value="this.formatDate(pickup.pickupDate)">
                    <input v-else type="text" readonly  class="form-control"/>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Assignee Name</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.assigneeName">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Assignee Phone</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.assigneePhone">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Assignee Company</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.assigneeCompany">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="address">Instructions/Comments</label>
                    <input type="text" readonly id="address" class="form-control" :value="pickup.instructions">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Vehicle Reg No.</label>
                    <input type="text" id="address" class="form-control" v-model="vehicle">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="address">Pickup Comments</label>
                    <input type="text" id="address" class="form-control" v-model="comments">
                  </div>
                </div>
  
              </div>
  
            </div>

            <strong class="mt-4">3. Stock Items to Collect</strong>
            <table class="table table-hover">
                <thead>
                    <th>No. </th>  
                    <th>Product Description</th>
                    <th>Product Code</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Qty Avail</th>
                    <th>Condition</th>
                    <th>Qty Assigned</th>
                    <th>Comment</th>
                    <th>Status</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="pickup.items?.length > 0">
                    <tr v-for="(item, index) in pickup.items" v-bind:key="item.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.code }}</td>
                        <td>{{ item.Product.unit.unit }}</td>
                        <td>{{ formatQty(item.quantity) }}</td>
                        <td>-</td>
                        <td>{{ item.condition }}</td>
                        <td><input type="number" min="0" class="form-control" :id="'assigned'+item.id" :value="item.quantityAssigned" /></td>
                        <td><input type="text" class="form-control" :id="'comment'+item.id" :value="item.collectComment" /></td>
                        <td>
                          <button v-if="item.status == 'completed'" class="btn btn-sm btn-success">Completed</button>
                        </td>
                        <td class="padding-top: 5px;">
                          <button v-if="item.isReturn == true" class="btn btn-default btn-sm" style="border: 1px solid #000; background-color: #f2f2f2;margin-right: 10px;">To Return</button>
                          <button v-else class="btn btn-default btn-sm" style="border: 1px solid #000;margin-right: 5px; " @click="returnItem(item.id)">Return</button>
                          <button v-if="item.status == 'completed'" class="btn btn-sm btn-success">Assigned</button>
                          <button v-else class="btn btn-default btn-sm" style="border: 1px solid #000; margin-left: 10px;" @click="assignItem(item.id)">Assign</button>
                        </td>
                    </tr>
                </tbody>
                <div v-else>
                  <label for="" class="alert alert-danger" style="margin: 10px auto;  display: block;">No items found</label>
                </div>
            </table>

            <div>
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="processPickup">Process</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="$router.push('/orders')">Close</button>
            </div>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>

  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  
  export default {
    name: 'ProcessPickup',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        displayOrder: "none",
        code:"",
        pickup:"",
        vehicle:"",
        comments: "",
        items:[],
        isVerified: false,
        isScheduled: false,
        pickupNoteId: "",
    }),
    mounted(){
        this.pickupNoteId = this.$route.params.id
    },
    methods:{
      formatBoolean(value){
        if(value){
          return "YES"
        } else{
          return "NO"
        }
      },
      async returnItem(itemId){
        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                itemId,
                pickupNoteId:this.pickupNoteId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/pickups/item/return', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.pickup = data.pickup;
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Item not set to return. Try again',
                text: data.message
            })
          }
      },
      async assignItem(itemId){
        const qty = document.getElementById('assigned'+itemId).value
        const comment = document.getElementById('comment'+itemId).value
        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                itemId,
                comment,
                qty,
                pickupNoteId:this.pickupNoteId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/pickups/item/assign', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.pickup = data.pickup;
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Item not assigned. Try again',
                text: data.message
            })
          }
      },
      async processPickup(){
        if(this.vehicle == ""){
          Swal.fire({
                icon: 'error',
                title: 'Pickup not Processed',
                text: 'The Vehicle Reg No is required'
            })
          return false;
        }
        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                vehicle: this.vehicle,
                comment: this.comments,
                pickupNoteId:this.pickupNoteId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/pickups/process', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.$router.push('/orders')
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Item not assigned. Try again',
                text: data.message
            })
          }
      },
      async verifyOrder () {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                code:this.code,
                pickupNoteId:this.pickupNoteId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/pickups/verify', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.displayOrder = "block";
              this.isVerified = true;
              this.pickup = data.pickup;
              this.isScheduled = data.pickup.scheduled;
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Pickup Note not verified. Try again',
                text: data.message
            })
          }
      }
      },
  }
  </script>


<style lang="scss" scoped>

input[readonly]{
  background-color: #cfcece !important;
}

$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>