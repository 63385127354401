<template>
  <div class="home">

    <Header :mainHeader="true"/>

    <section id="warehouse-inventory">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="enterprise-one">
              <h3>Pavicon (K) Limited</h3>
              <a href="#" class="btn-style">Change</a>
            </div>
            <h3>Inventory & Warehouse Overview</h3>
          </div>
          <div class="col-md-6">
            <div class="quick-actions">
              <span>Last updated: {{ date_now }}</span>
              <div class="form">
                <select>
                  <option value="Quick Actions">Quick Actions</option>
                  <option value="Edit">Edit</option>
                  <option value="Delete">Delete</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="warehouse-inventory-card-wrapper">
          <div class="warehouse-inventory-card">
            <p>No. of Stock
              Transactions Today</p>
            <div class="total">
              <a href="#">View more</a>
              <strong>87</strong>
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>Warehouse Dispatch Orders
              Received Today</p>
            <div class="total">
              <a href="#">View more</a>
              <strong>21</strong>
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>Warehouse Orders
              Processed Today</p>
            <div class="total">
              <a href="#">View more</a>
              <strong>15</strong>
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>Scheduled
              Collections</p>
            <div class="total">
              <a href="#">View more</a>
              <strong style="color: green;">2</strong>
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>POs Pending
              Approvals</p>
            <div class="total">
              <a href="#">View more</a>
              <strong style="color: orange;">6</strong>
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p style="color: red;">Total Unverified Stocks</p>
            <div class="total">
              <a href="#" style="color: red;">View more</a>
              <strong style="color: red;">1</strong>
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p style="color: orange;">Value of SOH* KES</p>
            <div class="total">
              <a href="#" style="color: orange;">View more</a>
              <strong style="color: orange;">46,000</strong>
            </div>
          </div>
        </div>
        <div class="today-order-wrapper">
          <div class="row">
            <div class="col-md-4 align-self-center">
              <h3>Today’s Orders (Dispatch & Pickup Notes)</h3>
              <div class="table-wrapper">
                <div class="today-table">
                  <div class="table-card">
                    <p><b>No.</b></p>
                    <p><b>Time</b></p>
                    <p><b>Note#</b></p>
                    <p><b>Status</b></p>
                    <p><b>Action</b></p>
                  </div>

                  <div class="table-card">
                    <p>1</p>
                    <p>08:41</p>
                    <p>12314</p>
                    <p><a href="#" class="btn-style small yellow">Pending</a></p>
                    <p><a href="#" class="btn-style small">View</a></p>
                  </div>
                  <div class="table-card">
                    <p>2</p>
                    <p>08:41</p>
                    <p>12314</p>
                    <p><a href="#" class="btn-style small green">Pending</a></p>
                    <p><a href="#" class="btn-style small">View</a></p>
                  </div>
                  <div class="table-card">
                    <p>3</p>
                    <p>08:41</p>
                    <p>12314</p>
                    <p><a href="#" class="btn-style small green">Pending</a></p>
                    <p><a href="#" class="btn-style small yellow">View</a></p>
                  </div>
                  <div class="table-card">
                    <p>4</p>
                    <p>08:41</p>
                    <p>12314</p>
                    <p><a href="#" class="btn-style small yellow">Pending</a></p>
                    <p><a href="#" class="btn-style small green">View</a></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-center">
              <h3>Stock Movement Analysis</h3>
             <div class="line-chart">
               <LineChart/>
             </div>
            </div>
            <div class="col-md-4 align-self-center">
              <h3>Stock Categories</h3>
              <div class="bar-chart">
                <BarChart/>
              </div>
            </div>
          </div>
        </div>
        <div class="system-user-manual">
          <div class="row">
            <div class="col-md-5 align-self-center">
              <h3>Transactions to monitor this month</h3>
              <ul>
                <li><p><strong>Stock requests</strong> – 4 View more</p></li>
                <li><p><strong>PO to review</strong> – 13 View more</p></li>
                <li><p><strong>Stocks to verify</strong> – 45 View more</p></li>
                <li><p><strong>Items to order</strong> – 15 View more</p></li>
                <li><p><strong>Price change</strong> – 8 View more</p></li>
              </ul>
            </div>
            <div class="col-md-4 align-self-center">
              <h3>Stock Levels to monitor</h3>
              <ul>
                <li><p><strong class="green">Normal </strong>- 83 items View more</p></li>
                <li><p><strong class="orange">Low </strong>- 23 items View more</p></li>
                <li><p><strong class="">Re-order </strong>- 15 items View more</p></li>
                <li><p><strong class="red">Out of Stock </strong>- 15 items View more</p></li>
              </ul>
            </div>
            <div class="col-md-3 align-self-center">
              <div class="user-manual">
                <div class="img-wrapper">
                  <i class="fa-solid fa-circle-question"></i>
                </div>
                <h3>System user manual</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

import Header from '../components/Header';
import LineChart from '../components/LineChart';
import BarChart from '../components/BarChart';



export default {
  name: 'HomePage',
  components: {
    Header,
    LineChart,
    BarChart,
  },
  data(){
    return{
      date_now: new Date().getDate()+"/"+(new Date().getUTCMonth() + 1)+"/"+new Date().getFullYear()+"  "+new Date().getHours()+":"+new Date().getMinutes(),
      url: process.env.VUE_APP_URL
    }
  },
}
</script>