<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Transactions </h3>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Unverified <br>
                Transactions</p>
              <strong class="red">8</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Stock-In <br>
                (Added)</p>
              <strong>37</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Stock-Out <br>
                (Removed)</p>
              <strong>50</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Total Stock <br>
                Transactions</p>
              <strong>87</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Split Receipt <br>
                Transactions </p>
              <strong>12</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Saved <br>
                Transactions</p>
              <strong class="green">4</strong>
            </div>
  
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Criteria</label>
                      <select class="form-control" v-model="criteria">
                        <option value="all">All</option>
                        <option value="with">With PO</option>
                        <option value="without">Without PO</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Status</label>
                      <select class="form-control" v-model="status">
                        <option value="all">All</option>
                        <option value="processed">Processed</option>
                        <option value="partial">Partial</option>
                        <option value="saved">Saved</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Verification</label>
                      <select class="form-control" v-model="verification">
                        <option value="all">All</option>
                        <option value="1">Verified</option>
                        <option value="0">Unverified</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Stock Category</label>
                      <select v-model="stockId" class="full" id="stockCategories"  v-if="nogs.length > 0">
                          <option value="0">All</option>
                          <option v-for="nog in nogs" v-bind:Key="nog.id" :value="nog.id">
                              {{ nog.nog }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No Stock Category Found.</h5> 
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Created By</label>
                      <select v-model="createdId" class="full" id="createdUsers"  v-if="users.length > 0">
                          <option value="0">Any</option>
                          <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                              {{ user.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No user found.</h5> 
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Verified By</label>
                      <select v-model="verifiedId" class="full" id="verifiedUsers"  v-if="users.length > 0">
                          <option value="0">Any</option>
                          <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                              {{ user.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No user found.</h5> 
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-primary" style="margin-top: 35px;" type="button" @click="search">Search</button>
                  </div>
                  

                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list">

            <strong>Transactions Table</strong>
            <keep-alive>
            <table id="transactionsTable" class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Reference No.</th>
                <th>PO No.</th>
                <th>Exception</th>
                <th>Status</th>
                <th>Verification</th>
                <th>Stock Category</th>
                <th>Handled By</th>
                <th>Action</th>
              </thead>
              
            </table>
          </keep-alive>
            <div v-if="batches?.length < 1">
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No transaction found</label>
            </div>

          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
  import Header from '../../components/Header';
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'  
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  export default {
    name: 'HomePage',
    components: {
      Header,
    },
    data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      permission: JSON.parse(localStorage.getItem('permission')),
      batches: [],
      dateFrom: "",
      dateTo: "",
      criteria: "all",
      verifiedId: "0",
      createdId: "0",
      verification: "all",
      status: "all",
      stockId: "0",
      dataTable:  null,
      processedData: []
    }),
    computed:{
      ... mapGetters (["getUsers", "getNogs"]),
      users () { return this.getUsers },
      nogs () { return this.getNogs },
    },
    mounted(){
      this.fetchUsers()
      this.fetchNogs()
      this.setupBatches()
    },
    methods:{
      ... mapActions(['fetchUsers', 'fetchNogs']),
      async setupBatches(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/batches', requestOptions)
        this.batches = await res.json()
        $("#transactionsTable_wrapper").innerHTML=""
        this.processedData = this.preprocessData(this.batches);
        this.initializeDataTable();

      },
      preprocessData(rawData) {
        return rawData.map(item => {
          let purchaseNo = "";
          let exception = "";
          let status =  "";
          let verification =  "";
          let stock = "";
          let handledBy = " - ";
          let action = "";

          if(item?.Purchase){
            purchaseNo = item.Purchase?.purchaseNo 
          }
          if(item?.Purchase?.exception == 0){
            exception = '<button class="btn btn-success btn-sm" style="margin-left: 5px;">OK</button>'
          } else if (item?.Purchase?.exception == 1) {
            exception = '<button class="btn btn-danger btn-sm" style="margin-left: 5px;">!</button>'
          }

          if(item.isSplit == true && item.status != 'saved' && item.status != 'cancelled' && item.mergeId == null){
            status = '<button class="btn btn-sm btn-primary">Partial</button>';
          } else if(item.status == 'processed'){
            status = '<button class="btn btn-sm btn-success">Processed</button>'
          } else if(item.status == 'cancelled'){
            status = '<button class="btn btn-sm btn-danger">Cancelled</button>'
          } else{
            status = `<button class="btn btn-sm btn-warning" style="text-transform: capitalize;">${item.status}</button>`
          }

          if(item.isVerified == true){
            verification = '<button class="btn btn-success btn-sm">Verified</button>'
          }else {
            verification = '<button class="btn btn-sm btn-warning">Unverified</button>'
          }

          if(item.nogId) {
            stock = item.Nog?.nog
          } else {
            stock = ' - '
          }
          if(item.User) {
            handledBy = item.User.name
          }

          if(item.status == 'saved' && this.permission?.batchVerify){
            action += `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 15px;" href="/transaction/savedbatch/${item.id}">View</a>`
          }
          if(item.status == 'processed' && this.permission?.batchVerify){
            action += `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 15px;" href="/transaction/processedbatch/${item.id}">View</a>`
          }
          action += `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 15px;" href="/transaction/pdfbatch/${item.id}">BPN PDF</a>`

          return {
            indexNo:"1",
            dateCreated: this.formatDate(item.createdAt),
            referenceNo:item.batchNo,
            purchaseNo,
            exception,
            status,
            verification,
            stock,
            handledBy,
            action
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#transactionsTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'dateCreated' },
              { data: 'referenceNo' },
              { data: 'purchaseNo' }, 
              { data: 'exception' }, 
              { data: 'status' }, 
              { data: 'verification' }, 
              { data: 'stock' }, 
              { data: 'handledBy' }, 
              { data: 'action' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      async search(){
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                criteria: this.criteria,
                verifiedId: this.verifiedId,
                createdId: this.createdId,
                verification: this.verification,
                status: this.status,
                stockId: this.stockId,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/batches/search', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.batches = data.batches
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: data.message
            })
        }
      },
    },
    beforeRouteLeave() {
      if (this.dataTable && typeof this.dataTable.destroy === 'function' && this.dataTable.parentNode) {
        this.dataTable.destroy();
      }
    }

  }
  </script>