<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Cancelled Orders</h3>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Todays Cancelled Orders</p>
              <strong class="blue">{{ dispatches.length + pickups.length }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" >
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Cancelled By.</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small">Search</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="purchases-list">
                <strong>Orders Table</strong>
                <div class="btn-group" role="group" style="float:right">
                  <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black;" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
                  <ul class="dropdown-menu">
                    <li style="width: 100%;"><a class="dropdown-item" href="#">CSV</a></li>
                    <li style="width: 100%;"><a class="dropdown-item" href="#">PDF</a></li>
                    <li style="width: 100%;"><a class="dropdown-item" href="#">EXCEL</a></li>
                  </ul>
                </div>
                <table class="table table-hover">
                    <thead>
                        <th>No.</th>
                        <th>Created Date</th>
                        <th>Cancelled Date</th>
                        <th>Order No.</th>
                        <th>Cancelled By</th>
                        <th>Reason for Cancellation</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <tr v-for="(dispatch, index) in dispatches" v-bind:key="dispatch.id">
                            <td>{{ index+1 }}</td>
                            <td>{{  formatDate(dispatch.createdAt) }}</td>
                            <td>{{  formatDate(dispatch.deletedAt) }}</td>
                            <td>{{  dispatch.dispatchNo }}</td>
                            <td>{{  dispatch.name }}</td>
                            <td>{{  dispatch.cancelReason }}</td>
                            <td><button class="btn btn-sm btn-default" style="border: 1px solid black;" @click="$router.push({name: 'PdfDispatch', params:{ id: dispatch.id}})">View PDF</button></td>
                        </tr>
                        <tr v-for="(pickup, index) in pickups" v-bind:key="pickup.id">
                            <td>{{ index+1 }}</td>
                            <td>{{  formatDate(pickup.createdAt) }}</td>
                            <td>{{  formatDate(pickup.deletedAt) }}</td>
                            <td>{{  pickup.pickupNo }}</td>
                            <td>{{  pickup.name }}</td>
                            <td>{{  pickup.cancelReason }}</td>
                            <td><button class="btn btn-sm btn-default" style="border: 1px solid black;" @click="$router.push({name: 'PickupPdf', params:{ id: pickup.id}})">View PDF</button> </td>
                        </tr>
                    </tbody>
                </table>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  
  import Header from '../../components/Header';
  
  export default {
    name: 'CancelledOrders',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        dispatches:[],
        pickups: [],
    }),
    mounted(){
      this.setupDispatches()
      this.setupPickups()
    },
    methods:{
      async setupPickups(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/pickups/cancelled', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.pickups = data.pickups;
        }
      },
      async setupDispatches(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/dispatches/cancelled', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.dispatches = data.dispatches;
        }
      },
    }
  }
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>