<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="warehouse-add">
        <div class="container mt-3">
          <h3>Locate Product {{ productName }}</h3>
          <div class="panel">
            <div class="form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="product-name">Product Name</label>
                    <Select2 class="form-control" v-if="products.length > 0" style="width: 100% !important;" v-model="productId" :options="productOptions" :settings="{ width: 'resolve', selectionCssClass: ':all:' }" />
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Product Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchProducts">Fetch Products</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                    <button class="btn-style small" style="margin-top: 35px" @click="locateProduct">Search</button>
                </div>
  
              </div>
  
            </div>
          </div>
          <div class="panel" :style="{ 'display': displaySearch }">
            <strong>2. Search Results</strong>
            <table class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Warehouse</th>
                <th>Quantity in Store</th>
                <th>Quantity Reserved</th>
                <th>Action</th>
              </thead>
              <tbody>
                    <tr v-for="(inventory, index) in warehouses" v-bind:key="inventory.id">
                        <td>{{ index+1 }}</td>
                        <td>{{  inventory.Warehouse?.name }}</td>
                        <td>{{ inventory.quantity }}</td>
                        <td>{{ inventory.quantityReserved }}</td>
                        <td>
                          <button class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 10px;" @click="this.$router.push('/warehouses/activity/'+inventory.id)">Activity</button>
                          <div class="btn-group" role="group">
                              <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                              <ul class="dropdown-menu">
                                  <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/products/'+inventory.Product.id)">View Product</a></li>
                                  <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/reserved/'+inventory.id)">Reserve Stock</a></li>
                                  <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/adjustments/'+inventory.id)">Adjust Stock</a></li>
                                  <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/transfers/'+inventory.id)">Transfer Stock</a></li>
                                  <li><a class="dropdown-item" href="#" >Add to PO In-tray</a></li>
                                  <li><a class="dropdown-item" href="#" >Add to Asset In-tray</a></li>
                              </ul>
                          </div>
                        </td>
                    </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import Select2 from 'vue3-select2-component';
  
  export default {
    name: 'LocateWarehousePage',
    components: {
      Header,
      Select2,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        productId: "",
        displaySearch: "none",
        warehouses: [],
        products:[],
        productName:"",
        productOptions:[],
    }),
    mounted(){
      this.setupProducts()
    },
    methods:{
      async setupProducts(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/products', requestOptions)
        this.products = await res.json()
        this.productOptions = [];
        this.productOptions.push({ id: 0, text: "All"})
        this.products.forEach(product => {
          this.productOptions.push({ id: product.id, text: product.name+" - "+product?.unit?.unit})
        });
      },
        async locateProduct () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    productId: this.productId
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/inventory/locate', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.displaySearch = "block",
                this.warehouses = data.warehouses
                this.productName = data.warehouses[0].Product?.name
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Location not found',
                    text: data.message
                })
            }
        },
        
    }
  }
</script>