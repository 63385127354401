<template>
  <div class="inventory">

    <Header :mainHeader="true"/>

    <section id="inventory-products">
      <div class="container">
        <h3>Add products</h3>
        <div class="row">
          <div class="col-md-6 align-self-center">
            <strong>1. Product Specifications</strong>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="upload-products">
              <a target="_blank" :href="`${urlServer}/files/exports/ProductTemplate.csv`" class="btn-style">Download Upload Template</a>
              <div class="form">
                <label for="upload-products" class="btn-style">Upload Products <i class="fa fa-upload"></i></label>
                <input type="file"  id="upload-products" placeholder="Upload Products" accept=".csv"  ref="fileUpload" @change="handleFileChange">
              </div>
            </div>
          </div>
        </div>
        <div class="product-specification">
          <div class="form" style="margin-top: 20px;">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="product-name">Product Name/Description <span style="color: red !important;">*</span></label>
                  <input type="text" class="form-control" id="product-name" placeholder="Enter name" v-model="name">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Item Code</label>
                  <input type="text" class="form-control" id="item-code" placeholder="Enter code" v-model="code">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="product-category">Product Category <span style="color: red !important;">*</span></label>
                  <select v-model="categoryId" class="form-control" id="product-category"  v-if="categories.length > 0">
                      <option v-for="category in categories" v-bind:Key="category.id" :value="category.id">
                          {{ category.category }}
                      </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Product Category.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchCategories">Fetch Product Categories</button>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="product-sub-category">Product Sub-cat <span style="color: red !important;">*</span></label>
                  <select v-model="subCategoryId" class="form-control" id="product-sub-category">
                      <option value="0" v-if="subcategories?.length == 0">None</option>
                      <option v-for="subcategory in subcategories" v-bind:Key="subcategory.id" :value="subcategory.id">
                          {{ subcategory.category }}
                      </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="purchase-price">Purchase Price (Cost of Product)</label>
                  <input type="number" class="form-control" id="purchase-price" placeholder="Enter Kshs" v-model="pricePurchase">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="retail-price">Retail Selling Price</label>
                  <input type="number" class="form-control" id="retail-price" placeholder="Enter Kshs" v-model="priceRetail">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="wholesale-price">Wholesale Selling Price</label>
                  <input type="number" class="form-control" id="wholesale-price" placeholder="Enter Kshs" v-model="priceWholesale">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="product-safety">Material Safety Data</label>
                  <div class="form-control">
                    <input type="file" id="product-safety" ref="fileSafety" @change="handleFileChange">
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="product-picture">Product Picture</label>
                  <div class="form-control">
                    <input type="file" id="product-picture" ref="filePicture" @change="handleFileChange">
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="product-alternative-name">Product Alternative Name</label>
                  <input type="text" class="form-control" id="product-alternative-name" placeholder="Enter name" v-model="alternativeName">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="product-unit">Unit <span style="color: red !important;">*</span></label>
                  <select v-model="unitId" class="full" id="product-unit"  v-if="units.length > 0">
                      <option v-for="unit in units" v-bind:Key="unit.id" :value="unit.id">
                          {{ unit.unit }}
                      </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Unit Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchUnits">Fetch Units</button>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="sub-unit">Sub-Unit <span style="color: red !important;">*</span></label>
                  <select v-model="subUnitId" class="full" id="sub-unit" >
                      <option value="0" v-if="subUnits?.length == 0">None</option>
                      <option v-for="subunit in subUnits" v-bind:Key="subunit.id" :value="subunit.id">
                          {{ subunit.unit }}
                      </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-weight">Item Weight</label>
                  <input type="number" min="0" class="form-control" id="item-weight" placeholder="Enter Kgs" v-model="itemWeight">
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="storage-dimension">Storage Dimension</label>
                  <input type="text" class="form-control" id="storage-dimension" placeholder="Enter Cubic Mtrs" v-model="storageDimension">
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="hs-code">HS Code</label>
                  <input type="text" class="form-control" id="hs-code" placeholder="Enter Code" v-model="hsCode">
                </div>
              </div>
              
              <div class="col-md-2">
                <div class="form-group">
                  <label for="shelfLife">Shelf Life</label>
                  <input type="number" min="0" class="form-control" id="shelfLife" placeholder="Enter No. of Days" v-model="shelfLife">
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="max-disc">Tax Rate (%)</label>
                  <input type="number" min="0" max="100" class="form-control" id="max-disc" placeholder="Enter Tax" v-model="tax">
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="barcode-serial">Barcode/Serial No</label>
                  <input type="text" class="form-control" id="barcode-serial" placeholder="Enter barcode"  v-model="barcode">
                </div>
              </div>

              
              <div class="col-md-3">
                <div class="check-button">
                  <div class="form-group">
                    <label>Incl. components</label>
                    <div class="components-button">
                      <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="inclComponent">
                        <label for="switcher-1"></label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="product-stock-levels">
          <strong>2. Product Stock Levels</strong>
          <div class="form">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="min-stock-level">Min Stock Level</label>
                  <input type="number" min=0 class="form-control" id="min-stock-level" placeholder="Enter quantity" v-model="stockMin">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="max-stock-level">Max Stock Level</label>
                  <input type="number" min="0" class="form-control" id="max-stock-level" placeholder="Enter quantity" v-model="stockMax">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="order-stock-level">Order Stock Level</label>
                  <input type="number" min="0" class="form-control" id="order-stock-level" placeholder="Enter quantity" v-model="stockOrder">
                </div>
              </div>
              <div class="col-md-2">
                <button class="btn-style" style="margin-top:35px" @click="postProduct"> Create</button>
              </div>
            </div>
          </div>
          <br>
          <strong>3. Products Table</strong>
          <table class="table table-hover"  v-if="products.length > 0">
            <thead>
              <th>No.</th>
              <th>Product Name</th>
              <th>Item Code</th>
              <th>Category</th>
              <th>Unit</th>
              <th>Value (Kshs)</th>
              <th>Min Stock</th>
              <th>Max Stock</th>
              <th>Order Stock</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr v-for="(product, index) in products" v-bind:key="product.id">
                <td>{{ index+1 }}</td>
                <td>{{ product.name }}</td>
                <td>{{ product.code }}</td>
                <td>{{ product?.Category?.category }}</td>
                <td>{{ product?.unit?.unit }}</td>
                <td>{{ formatMoney(product.pricePurchase) }}</td>
                <td>{{ formatQty(product.stockMin) }}</td>
                <td>{{ formatQty(product.stockMax) }}</td>
                <td>{{ formatQty(product.stockOrder) }}</td>
                <td>
                  <a href="#" class="btn-style small" style="margin-right:5px" @click="$router.push({name: 'ViewProduct', params:{ id: product.id}})">View</a>
                </td>
              </tr>
            </tbody>
          </table>
          <h4 v-else style="color:red; text-align: center; margin-top: 30px;">No product found</h4>

        </div>
      </div>
    </section>

  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../../components/Header';
import axios from 'axios'

export default {
  name: 'ProductPage',
  components: {
    Header,
  },
  data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      urlServer:process.env.VUE_APP_URL,
      name: "",
      code: "",
      pricePurchase: "",
      categoryId: 0,
      subCategoryId: 0,
      subcategories: [],
      priceRetail: "",
      priceWholesale: "",
      alternativeName:"",
      unitId:"",
      subUnits: [],
      shelfLife:"",
      subUnitId:0,
      itemWeight:"",
      storageDimension:"",
      hscode:"",
      maxDisc:"",
      tax:"",
      barcode:"",
      inclComponent:false,
      companyAsset:false,
      stockMin:"",
      stockMax:"",
      stockOrder:"",
      assetNo:"",
      picture:"",
      safety:"",
      uploadProduct:"",
  }),
  computed:{
    ... mapGetters (["getUnits", "getAllUnits", "getCategories", "getProducts"]),
    units () { return this.getUnits },
    allUnits () { return this.getAllUnits },
    categories () { return this.getCategories },
    products () { return this.getProducts }
  },
  methods:{
      ... mapActions(['fetchUnits','fetchAllUnits', 'fetchCategories','fetchProductsAdded']),
      async postProduct () {
          let formaData = new FormData()
          formaData.append("name", this.name)
          formaData.append("code", this.code)
          formaData.append("pricePurchase",this.pricePurchase)
          formaData.append("categoryId",this.categoryId)
          formaData.append("subCategoryId",this.subCategoryId)
          formaData.append("priceRetail",this.priceRetail)
          formaData.append("priceWholesale",this.priceWholesale)
          formaData.append("alternativeName", this.alternativeName)
          formaData.append("unitId",this.unitId)
          formaData.append("subUnitId",this.subUnitId)
          formaData.append("weightMeasurement",this.itemWeight)
          formaData.append("dimensions",this.storageDimension)
          formaData.append("hscode", this.hscode)
          formaData.append("shelfLife", this.shelfLife)
          formaData.append("maxDiscount", this.maxDisc)
          formaData.append("tax", this.tax)
          formaData.append("components",this.inclComponent)
          formaData.append("companyAsset",this.companyAsset)
          formaData.append("barcode", this.barcode)
          formaData.append("stockMin",this.stockMin)
          formaData.append("stockMax",this.stockMax)
          formaData.append("stockOrder",this.stockOrder)
          formaData.append("assetNo", this.assetNo)
          formaData.append("picture", this.picture)
          formaData.append("safety", this.safety)
          axios.post(process.env.VUE_APP_URL+'/products', 
          formaData, 
          {
            headers:{ 
              "Content-Type": "multipart/form-data", 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            if(data.data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Product created',
                    text: data.data.message
                })
              window.location.reload();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product not created',
                    text: data.data.message
                })
            }
          })
      },
      async uploadProducts () {
          let formaData = new FormData()
          formaData.append("file", this.uploadProduct)
          axios.post(process.env.VUE_APP_URL+'/products/upload', 
          formaData, 
          {
            headers:{ 
              "Content-Type": "multipart/form-data", 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            if(data.data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Products uploaded successfully',
                    text: data.data.message,
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm'
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                })
              
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product upload failed',
                    text: data.data.message
                })
            }
          })
      },
      isSubUnit(element) {
        return element.unitId == this.unitId;
      },
      isSubCategory(element) {
        return element.id == this.categoryId;
      },
      handleFileChange() {
        this.picture = this.$refs.filePicture.files[0];
        this.safety = this.$refs.fileSafety.files[0];
        this.uploadProduct = this.$refs.fileUpload.files[0];
      }
      
  },
  mounted(){
      this.fetchUnits()
      this.fetchAllUnits()
      this.fetchCategories()
      this.fetchProductsAdded()
  },
  watch: {
    unitId() {
      this.subUnits = []
      this.subUnits = this.allUnits.filter(this.isSubUnit);
    },
    categoryId() {
      this.subcategories = []
      const category = this.categories.filter(this.isSubCategory);
      this.subcategories = category[0].subcategories;
    },
    uploadProduct(){
      this.uploadProducts()
    }
  }
}
</script>