<template>
    <div class="transaction" :key="componentKey">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>View Products</h3>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Product Name / Description</label>
                      <input type="text" class="form-control" v-model="name">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Item Code</label>
                      <input type="text" class="form-control" v-model="code">
                    </div>
                  </div>
                <div class="col-md-3">
                    <div class="form-group">
                    <label for="product-category">Product Category</label>
                    <select v-model="categoryId" class="form-control" id="product-category"  v-if="categories.length > 0">
                        <option v-for="category in categories" v-bind:Key="category.id" :value="category.id">
                            {{ category.category }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Product Category.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchCategories">Fetch Product Categories</button>
                    </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                    <label for="product-sub-category">Product Sub-cat.</label>
                    <select v-model="subCategoryId" class="form-control" id="product-sub-category">
                        <option value="0" v-if="subcategories?.length == 0">None</option>
                        <option v-for="subcategory in subcategories" v-bind:Key="subcategory.id" :value="subcategory.id">
                            {{ subcategory.category }}
                        </option>
                    </select>
                    </div>
                </div>
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small" @click="filter()">Filter</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="suppliers-list">
            <strong>Products List</strong>
            <keep-alive>
            <table class="table table-hover" id="productsTable">
                <thead>
                <th>No.</th>
                <th>Product Name</th>
                <th>Alternative Name</th>
                <th>Item Code</th>
                <th>Category</th>
                <th>Unit</th>
                <th>Value (KES)</th>
                <th>Min Stock</th>
                <th>Max Stock</th>
                <th>Order Stock</th>
                <th>Action</th>
                </thead>
            </table>
          </keep-alive>
            <h4  v-if="products.length < 1" style="color:red; text-align: center; margin-top: 30px;">No product found</h4>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
import { mapGetters, mapActions } from 'vuex'
import Header from '../../../components/Header';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {
    name: 'ProductsPage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        name: "",
        code: "",
        categoryId: 0,
        subCategoryId: 0,
        products: [],
        dataTable:  null,
        processedData: [],
        componentKey: 0
    }),
    computed:{
        ... mapGetters (["getCategories"]),
        categories () { return this.getCategories },
    },
    mounted(){
        this.fetchCategories()
        this.getProducts()
    },
    methods:{
      ... mapActions(['fetchCategories']),
      async getProducts () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/products', requestOptions)
            this.products = await res.json()
            this.processedData = this.preprocessData(this.products);
            this.initializeDataTable();
        },
      isSubCategory(element) {
        return element.id == this.categoryId;
      },
      preprocessData(rawData) {
        return rawData.map(item => {

          return {
            indexNo:"1",
            createdAt: this.formatDate(item.createdAt),
            productName: item.name,
            productCode: item.code,
            productAlt: item.alternativeName,
            category: item.Category?.category,
            unit: item.unit?.unit,
            value: this.formatMoney(item.pricePurchase),
            stockMin: this.formatQty(item.stockMin),
            stockMax: this.formatQty(item.stockMax),
            stockOrder: this.formatQty(item.stockOrder),
            action: `<a href="/inventory/products/${item.id}" class="btn-style small" style="margin-right:5px">View</a>`
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#productsTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'productName' }, 
              { data: 'productAlt' }, 
              { data: 'productCode' }, 
              { data: 'category' }, 
              { data: 'unit' }, 
              { data: 'value' },
              { data: 'stockMin' }, 
              { data: 'stockMax' }, 
              { data: 'stockOrder' }, 
              { data: 'action' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Products\n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Products\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
    },
    watch: {
        categoryId() {
        this.subcategories = []
        const category = this.categories.filter(this.isSubCategory);
        this.subcategories = category[0].subcategories;
        }
    }
  }
  </script>

  <style scoped>
  .suppliers-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>