<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>Add Supplier</h3>
          <strong>1. Add Details</strong>
          <div>
            <div class="form">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-name">Supplier Name</label>
                    <input type="text" class="form-control" id="product-name" v-model="name">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Business Description</label>
                    <input type="text" class="form-control" id="item-code" v-model="natureBusiness">
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="address">Office Address</label>
                    <input type="text" v-model="address" id="address" class="form-control">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Supplier Contact Person</label>
                    <input type="text" class="form-control" id="item-code" v-model="contact">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Mobile No.</label>
                    <input type="text" class="form-control" id="item-code" v-model="phone">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Email</label>
                    <input type="email" class="form-control" id="item-code" v-model="email">
                  </div>
                </div>
                <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Pre-qualified</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="qualified">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-1">
                    <button v-if="supplierId == ''" class="btn-style small" style="margin-top: 35px" @click="postSupplier">Add</button>
                </div>
  
              </div>
  
            </div>
          </div>
          <div :style="{ 'display': displayDocuments }">
            <strong>2. Add Documents</strong>
            <table class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Document Name</th>
                <th>Renewal/Expiry Date</th>
                <th>Upload Document</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr>
                    <td>1</td>
                    <td><input type="text" placeholder="Enter name" class="form-control" v-model="documentName"></td>
                    <td><input type="date" placeholder="Choose date" class="form-control" v-model="expiry"></td>
                    <td><input type="file"  class="form-control" ref="fileDocument" @change="handleFileChange"></td>
                    <td><button class="btn btn-default btn-sm pull-right" style="border:1px solid black;" @click="postSupplierDocument">Add</button></td>
                </tr>
                <tr v-for="(document,index) in documents" v-bind:key="document.id">
                  <td>{{ index+1 }}</td>
                  <td>{{ document.name }}</td>
                  <td>{{ formatDate(document.expiry) }}</td>
                  <td><a target="_blank" :href="`${urlServer}/files/${document.document}`" class="btn btn-sm btn-primary">View Document</a></td>
                  <td>
                    <button class="btn btn-sm btn-danger" @click="deleteSupplierDocument(document.id)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      </section>
      
      <div>
        <button class="btn btn-default" style="float: right; margin-right: 15px; border: 1px solid #000;" @click="this.$router.push('/suppliers')">Close</button>
      </div>
  
    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import axios from 'axios'
  
  export default {
    name: 'AddSupplierPage',
    components: {
      Header,
    },
    data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      urlServer:process.env.VUE_APP_URL,
      supplierId: "",
      displayDocuments: "none",
      name: "",
      natureBusiness: "",
      address: "",
      contact: "",
      phone: "+254",
      email: "",
      qualified: true,
      document: "",
      documents: [],
      documentName: "",
      expiry:""
    }),
    methods:{
        async postSupplier () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.name,
                    business: this.natureBusiness,
                    address: this.address,
                    contact: this.contact,
                    phone: this.phone,
                    email: this.email,
                    prequalified: this.qualified,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/suppliers', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.supplierId = data.supplier.id
                this.displayDocuments = "block"
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Supplier not created',
                    text: data.message
                })
            }
        },
        async postSupplierDocument () {
          const formData = new FormData();
          formData.append("name", this.documentName)
          formData.append("expiry", this.expiry)
          formData.append("supplierId", this.supplierId)
          formData.append("file", this.document)

          axios.post(process.env.VUE_APP_URL+'/suppliers/document', 
            formData, 
            {
              headers:{ 
                "Content-Type": "multipart/form-data", 
                'Authorization': 'Bearer '+this.token 
              }}).then(function(data) {
              if(data.data.status == true){
                this.documentName = "";
                this.expiry = "";
                this.document = "";
                this.documents = data.data.documents
              }else{
                  Swal.fire({
                      icon: 'error',
                      title: 'Supplier Document not created',
                      text: data.data.message
                  })
              }
            }.bind(this))
        },
        handleFileChange() {
          this.document = this.$refs.fileDocument.files[0];
        },
        async deleteSupplierDocument (documentid) {
          const requestOptions = {
              method: "GET",
              headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '+this.token 
              },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/suppliers/document/'+documentid, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Supplier Document deleted successfully',
                  text: 'Supplier Document deleted'
              })
              this.documents = data.documents;
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Supplier Document not deleted',
                  text: data.message
              })
          }
        },
        
    }
  }
  </script>

  <style lang="scss" scoped>

$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>