import { createStore } from 'vuex'

//const base_url = "http://localhost:3000"    //Local
const base_url = "https://paviconapi.ubuniworks.com"    //Local

// const base_url = "http://80e9-197-237-144-194.eu.ngrok.io" //Ngrok
// const base_url = process.env.APP_VUE_URL
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))};
export default createStore({
  state: {
    units: [],
    subUnits: [],
    allUnits: [],
    categories: [],
    subcategories: [],
    payments: [],
    purposes: [],
    purposesSpecify: [],
    tags: [],
    nogs: [],
    users: [],
    products: [],
    productOptions: [],
    suppliers: [],
    supplierDcouments: [],
    warehouses: [],
    purchases: {},
    batches: [],
    batchItems: [],
    dispatches: [],
    pickups: [],
    pickup:{},
    approvals: [],
    approvalspending: [],
    purchasenos: [],
    roles: [],
    notes: [],
  },
  mutations: { //synchrous
    setUnits (state, payload){
      state.units = payload
    },
    setSubUnits (state, payload){
      state.subUnits = payload
    },
    setAllUnits (state, payload){
      state.allUnits = payload
    },
    setCategories (state, payload){
      state.categories = payload
    },
    setSubCategories (state, payload){
      state.subcategories = payload
    },
    setPayments (state, payload){
      state.payments = payload
    },
    setPurposes (state, payload){
      state.purposes = payload
    },
    setPurposesSpecify (state, payload){
      state.purposesSpecify = payload
    },
    setTags (state, payload){
      state.tags = payload
    },
    setNogs (state, payload){
      state.nogs = payload
    },
    setUsers (state, payload){
      state.users = payload
    },
    setProducts (state, payload){
      state.products = payload
    },
    setProductOptions (state, payload){
      var optionArray = []
      payload.forEach(product => {
        optionArray.push({ id: product.id, text: product.name})
      });
      state.productOptions = optionArray
    },
    setSuppliers (state, payload){
      state.suppliers = payload
    },
    setSupplierDocuments (state, payload){
      state.supplierDcouments = payload
    },
    setWarehouses (state, payload){
      state.warehouses = payload
    },
    setPurchases (state, payload){
      state.purchases = payload
    },
    setBatches (state, payload){
      state.batches = payload
    },
    setBatchItems (state, payload){
      state.batchItems = payload
    },
    setDispatches (state, payload){
      state.dispatches = payload
    },
    setPickups (state, payload){
      state.pickups = payload
    },
    setPickup (state, payload){
      state.pickup = payload
    },
    setApprovals (state, payload){
      state.approvals = payload
    },
    setApprovalsPending (state, payload){
      state.approvalspending = payload
    },
    setPurchasenos (state, payload){
      state.purchasenos = payload
    },
    setRoles (state, payload){
      state.roles = payload
    },
    setNotes (state, payload){
      state.notes = payload
    },
  },

  actions: { //asynchrous
    async fetchUnits (state) {
      const res = await fetch(base_url+'/units',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setUnits",data)
    },
    async fetchSubUnits (state) {
      const res = await fetch(base_url+'/units/subunits',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setSubUnits",data)
    },
    async fetchAllUnits (state) {
      const res = await fetch(base_url+'/units/all',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setAllUnits",data)
    },
    async fetchCategories (state) {
      const res = await fetch(base_url+'/category',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCategories",data)
    },
    async fetchSubCategories (state) {
      const res = await fetch(base_url+'/category/subcategories',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setSubCategories",data)
    },
    async fetchPayments (state) {
      const res = await fetch(base_url+'/payments',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPayments",data)
    },
    async fetchPurposes (state) {
      const res = await fetch(base_url+'/purposes',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPurposes",data)
    },
    async fetchPurposesSpecify (state, payload) {
      const res = await fetch(base_url+'/purposes/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPurposesSpecify",data)
    },
    async fetchTags (state) {
      const res = await fetch(base_url+'/tags',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTags",data)
    },
    async fetchNogs (state) {
      const res = await fetch(base_url+'/nogs',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setNogs",data)
    },
    async fetchUsers (state) {
      const res = await fetch(base_url+'/users',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setUsers",data)
    },
    async fetchProducts (state) {
      const res = await fetch(base_url+'/products',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProducts",data)
      state.commit("setProductOptions",data)
    },
    async fetchProductsAdded (state) {
      const res = await fetch(base_url+'/products/added',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProducts",data)
    },
    async fetchSuppliers (state) {
      const res = await fetch(base_url+'/suppliers',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setSuppliers",data)
    },
    async fetchWarehouses (state) {
      const res = await fetch(base_url+'/warehouses',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setWarehouses",data)
    },
    async fetchPurchases (state) {
      const res = await fetch(base_url+'/purchases',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPurchases",data)
    },
    async fetchBatches (state) {
      const res = await fetch(base_url+'/batches',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setBatches",data)
    },
    async fetchDispatches (state) {
      const res = await fetch(base_url+'/dispatches',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setDispatches",data)
    },
    async fetchPickups (state) {
      const res = await fetch(base_url+'/pickups',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPickups",data)
    },
    async fetchPickup (state, payload) {
      const res = await fetch(base_url+'/pickups/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPickup",data.pickup)
    },
    async fetchApprovals (state) {
      const res = await fetch(base_url+'/approvals',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setApprovals",data)
    },
    async fetchApprovalsPending (state) {
      const res = await fetch(base_url+'/approvals/pending',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setApprovalsPending",data)
    },
    async fetchPurchasenos (state) {
      const res = await fetch(base_url+'/purchases/purchasenos',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPurchasenos",data)
    },
    async fetchRoles (state) {
      const res = await fetch(base_url+'/permissions',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setRoles",data)
    },
    async fetchNotes (state) {
      const res = await fetch(base_url+'/incompletenotes',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setNotes",data)
    },
  },
  modules: {
  },
  getters: {
    getUnits : state => state.units,
    getSubUnits : state => state.subUnits,
    getAllUnits : state => state.allUnits,
    getCategories : state => state.categories,
    getSubCategories : state => state.subcategories,
    getPayments : state => state.payments,
    getPurposes : state => state.purposes,
    getPurposeSpecify : state => state.purposesSpecify,
    getTags : state => state.tags,
    getNogs : state => state.nogs,
    getUsers : state => state.users,
    getProducts : state => state.products,
    getProductOptions : state => state.productOptions,
    getSuppliers : state => state.suppliers,
    getWarehouses : state => state.warehouses,
    getPurchases : state => state.purchases,
    getBatches : state => state.batches,
    getDispatches : state => state.dispatches,
    getPickups : state => state.pickups,
    getPickup : state => state.pickup,
    getApprovals : state => state.approvals,
    getApprovalsPending : state => state.approvalspending,
    getPurchasenos : state => state.purchasenos,
    getRoles : state => state.roles,
    getNotes : state => state.notes,
  }
})
