<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>{{ product?.name }} 
            <button style="float: right; margin-left: 15px;" class="btn btn-danger" @click="deleteProductModal()">Delete Product</button>
            <button style="float: right;" class="btn btn-primary" @click="$router.push({name: 'EditProduct', params:{ id: product.id}})">Edit Product</button>
          </h3>
          <strong>Product Details</strong>
          <div>
            <div class="form">
                <div class="row">
                  <div class="col-md-3">
                    <img v-if="product?.picture" :src="`${urlServer}/files/${product?.picture}`" alt="" style="max-height: 225px; margin-bottom: 10px;">
                  </div>

                  <div class="col-md-9">
                    <table class="table table-bordered">
                      <tbody>
                        <tr><th>Name</th><td>{{ product?.name }}</td><th>Category</th><td>{{ product?.Category?.category }}</td><th>Purchase Price</th><td>{{ formatMoney(product?.pricePurchase) }}</td></tr>
                        <tr><th>Alt. Name</th><td>{{ product?.alternativeName }}</td><th>Sub-category</th><td></td><th>Retail Price</th><td>{{ formatMoney(product?.priceRetail) }}</td></tr>
                        <tr><th>Item Code</th><td>{{ product?.code }}</td><th>Unit</th><td>{{ product?.unit?.unit }}</td><th>Wholesale Price</th><td>{{ formatMoney(product?.priceWholesale) }}</td></tr>
                        <tr><th>HS Code</th><td>{{ product?.hscode }}</td><th>Sub Unit</th><td>-</td><th>Tax Rate</th><td>{{ product?.tax }}</td></tr>
                        <tr><th>Weight</th><td>{{ product?.weightMeasurement }}</td><th>Storage Dimension</th><td>{{ product?.dimensions }}</td><th>Shelf Life</th><td>{{ product?.shelfLife }}</td></tr>
                        <tr><th>Min Stock</th><td>{{ formatQty(product?.stockMin) }}</td><th>Max Stock</th><td>{{ formatQty(product?.stockMax) }}</td><th>Order Stock</th><td>{{ formatQty(product?.stockOrder) }}</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
          <div>
            <strong>Warehouse</strong>
            <table class="table table-hover" v-if="product?.inventory?.length > 0">
              <thead>
                <th>No.</th>
                <th>Warehouse</th>
                <th>Warehouse Code</th>
                <th>Qty On Hand</th>
                <th>Qty Reserved</th>
                <th>Available Qty</th>
              </thead>
              <tbody>
                <tr v-for="(inventory,index) in product?.inventory" v-bind:key="inventory.id">
                  <td>{{ index+1 }}</td>
                  <td>{{ inventory.Warehouse?.name }} <button v-if="inventory.Warehouse?.isVirtual == true" class="btn btn-sm btn-info" style="float: right;">Virtual</button></td>
                  <td>{{ inventory.Warehouse?.code }}</td>
                  <td>{{ calculateTotal((inventory.quantity + inventory.quantityReserved),'onhand') }}</td>
                  <td>{{ calculateTotal(inventory.quantityReserved,'reserved') }}</td>
                  <td>{{ calculateTotal(inventory.quantity,'available') }}</td>
                </tr>
              </tbody>
            </table>
            <div v-else>
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">Not found</label>
            </div>
          </div>
  
          <div class="mt-4">
            <strong>Suppliers & Purchase Orders</strong>
            <table class="table table-hover" v-if="product?.PurchaseProduct?.length > 0">
              <thead>
                <th>No.</th>
                <th>Supplier</th>
                <th>Date Fulfilled</th>
                <th>PO No.</th>
                <th>Total Qty Supplied</th>
                <th>LPO Price</th>
                <th>Unit Price</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(purchase,index) in product?.PurchaseProduct" v-bind:key="purchase.id">
                  <td>{{ index+1 }}</td>
                  <td>{{ purchase.Purchase.Supplier?.name }}</td>
                  <td>{{ formatDate(purchase.Purchase.updatedAt) }}</td>
                  <td>{{ purchase.Purchase.purchaseNo }}</td>
                  <td>{{ formatQty(purchase.quantity) }}</td>
                  <td>{{ formatMoney(purchase.price) }}</td>
                  <td>{{ formatMoney(Math.round(purchase.price/purchase.quantity)) }}</td>
                  <td>
                    <button class="btn btn-sm btn-primary" @click="this.$router.push('/purchases/pdf/'+purchase.id)">View LPO </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else>
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No LPO found</label>
            </div>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  
  export default {
    name: 'ViewProductPage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        product: "",
        productId: "",
        totalOnhand:0,
        totalReserved:0,
        totalAvailable:0,
    }),
    mounted(){
        this.productId = this.$route.params.id
        this.setupProduct(this.productId)
    },
    methods:{
      async setupProduct(itemId){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/products/'+itemId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.product = data.product;
            this.productId= data.product.id;
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Product not found. Try again',
                text: data.message
            })
        }
      },
      deleteProductModal(){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#312068',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteProduct()
          }
        })
      },
      async deleteProduct(){
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/products/'+this.productId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.product = data.product;
            this.productId= data.product.id;
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Product not found. Try again',
                text: data.message
            })
        }
      },
      calculateTotal(value, type){
        if(type == 'onhand'){
          this.totalOnhand = this.totalOnhand + value
        } else if(type == 'reserved'){
          this.totalReserved = this.totalReserved + value
        } else if(type == 'available'){
          this.totalAvailable = this.totalAvailable + value
        }
        return value;
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  
$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>