<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Stock Adjustment Logs</h3>
  
          <div class="filter-search" style="margin-top: 15px;">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
  
          <div class="filter-search" style="margin-top: 20px;">
            <strong>Stock Adjustment Log List</strong>
            <keep-alive>
            <table class="table table-hover" id="adjustmentsTable">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Activity</th>
                <th>Product Description</th>
                <th>Warehouse</th>
                <th>Qty Before</th>
                <th>Qty Adjusted</th>
                <th>New Qty Avail</th>
                <th>Reason</th>
                <th>Requested By</th>
                <th>Status</th>
              </thead>
            </table>
          </keep-alive>

            <div v-if="activities.length < 1">
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No log found</label>
            </div>

          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
  export default {
    name: 'StockAdjsutments',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        dataTable:  null,
        processedData: []
    }),
    mounted(){
        this.getActivities()
    },
    methods:{
        async getActivities () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/stockadjustments', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.activities = data.stocks
                this.processedData = this.preprocessData(data.stocks);
                this.initializeDataTable();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Stock Adjustment List not found',
                    text: data.message
                })
            }
        },
      preprocessData(rawData) {
        return rawData.map(item => {
          let status =  "";
          
          if(item.status == 'pending'){
            status = '<button class="btn btn-warning btn-sm" >Pending</button>'
          }
          if(item.status == 'done'){
            status = '<button class="btn btn-success btn-sm" >Completed</button>'
          }

          return {
            indexNo:"1",
            createdAt: this.formatDate(item.createdAt),
            type: item.type,
            productName: item.Product.name,
            warehouse: item.Inventory.Warehouse.name,
            oldQuantity: this.formatQty(item.oldQuantity),
            quantity: this.formatQty(item.quantity),
            newQuantity: this.formatQty(item.newQuantity),
            comment: item.comment,
            userName: item.User?.name,
            status
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#adjustmentsTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'createdAt' },
              { data: 'type' },
              { data: 'productName' }, 
              { data: 'warehouse' }, 
              { data: 'oldQuantity' }, 
              { data: 'quantity' }, 
              { data: 'newQuantity' }, 
              { data: 'comment' },
              { data: 'userName' }, 
              { data: 'status' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Stock Adjustments\n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Stock Adjustments\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
    }
  }
  </script>