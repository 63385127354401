<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Reserved Stock Activity</h3>
          <table class="table table-hover">
            <thead>
                <th>Product Description</th>
                <th>Reserved By</th>
                <th>Warehouse</th>
            </thead>
            <tbody>
                <tr>
                    <td>{{ productName }}</td>
                    <td>{{ reservedBy }}</td>
                    <td>{{ warehouseName }}</td>
                </tr>
            </tbody>
          </table>
            <strong>Stock Reserved Activity List</strong>
            <table class="table table-hover" v-if="activities.length > 0">
              <thead>
                <th>No.</th>
                <th>Date </th>
                <th>Type</th>
                <th>Quantity</th>
                <th>Activity By</th>
                <th>Comment</th>
              </thead>
              <tbody>
                <tr v-for="(activity, index) in activities" v-bind:key="activity.id">
                  <td>{{ (index+1) }}</td>
                  <td>{{ formatDate(activity.createdAt) }}</td>
                  <td v-if="activity.type == 'reserve'">Reserve</td>
                  <td v-if="activity.type == 'release'">Release</td>
                  <td>{{ formatQty(activity.quantity) }}</td>
                  <td>{{ activity.User?.name }}</td>
                  <td>{{ activity.comment }} {{ activity.note }}</td>
                </tr>
              </tbody>
            </table>

            <div v-else>
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No activity found</label>
            </div>

          </div>
      </section>

    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
    
  export default {
    name: 'StockReservedActivity',
    components: {
      Header
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        productName:"",
        reservedBy: "",
        warehouseName: "",
        reservedId: "",
        reserved: "",
    }),
    mounted(){
        this.reservedId = this.$route.params.id
        this.getActivities(this.reservedId)
    },
    methods:{
        async getActivities (reservedId) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/stockreserved/'+reservedId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.activities = data.activities
                this.reserved = data.stockReserved
                this.productName = data.stockReserved.Product?.name
                this.warehouseName = data.stockReserved.Inventory?.Warehouse?.name + " - " + data.stockReserved.Inventory?.Warehouse?.description
                this.reservedBy = data.stockReserved.User?.name
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Stock Reserved List not found',
                    text: data.message
                })
            }
        }
    }
  }
  </script>