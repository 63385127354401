<template>
  <div class="inventory">

    <Header :mainHeader="true"/>

    <section id="suppliers-add">
      <div class="container">
        <h3>View Supplier <button style="float: right;" class="btn btn-danger" @click="deleteSupplier()">Delete Supplier</button></h3>
        <strong>1. Details</strong>
        <div>
          <div class="form">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="product-name">Supplier Name</label>
                  <input type="text" class="form-control" id="product-name" v-model="name">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Nature of Business</label>
                  <input type="text" class="form-control" id="item-code" v-model="natureBusiness">
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label for="address">Office Address</label>
                  <input type="text" v-model="address" id="address" class="form-control">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Supplier Contact Person</label>
                  <input type="text" class="form-control" id="item-code" v-model="contact">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Mobile No.</label>
                  <input type="text" class="form-control" id="item-code" v-model="phone">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Email</label>
                  <input type="email" class="form-control" id="item-code" v-model="email">
                </div>
              </div>
              <div class="col-md-2">
                  <div class="check-button">
                  <div class="form-group">
                      <label>Pre-qualified</label>
                      <div class="components-button">
                  <span class="switcher switcher-1">
                      <input type="checkbox" id="switcher-1" v-model="qualified">
                      <label for="switcher-1"></label>
                  </span>
                      </div>
                  </div>
                  </div>
              </div>
              <div class="col-md-1">
                  <button class="btn-style small" style="margin-top: 35px" @click="postUpdate">Update</button>
              </div>

            </div>

          </div>
        </div>
        <div :style="{ 'display': displayDocuments }">
          <strong>2. Documents</strong>
          <table class="table table-hover">
            <thead>
              <th>No.</th>
              <th>Document Name</th>
              <th>Renewal/Expiry Date</th>
              <th>Uploaded Document</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr>
                  <td>#</td>
                  <td><input type="text" placeholder="Enter name" class="form-control" v-model="documentName"></td>
                  <td><input type="date" placeholder="Choose date" class="form-control" v-model="expiry"></td>
                  <td><input type="file"  class="form-control" ref="fileDocument" @change="handleFileChange"></td>
                  <td><button class="btn btn-default btn-sm pull-right" style="border:1px solid black;" @click="postUpdateDocument">Add</button></td>
              </tr>
              <tr v-for="(document,index) in documents" v-bind:key="document.id">
                <td>{{ index+1 }}</td>
                <td>{{ document.name }}</td>
                <td>{{ formatDate(document.expiry) }}</td>
                <td><a :href="`${urlServer}/files/${document.document}`" class="btn btn-sm btn-primary">View Document</a></td>
                <td>
                  <button class="btn btn-sm btn-danger" @click="deleteSupplierDocument(document.id)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-4">
          <strong>3. Purchase Order to-date</strong>
          <keep-alive>
          <table class="table table-hover" id="supplierPurchaseTable">
            <thead>
              <th>No.</th>
              <th>Date</th>
              <th>PO No.</th>
              <th>Dept</th>
              <th>Project</th>
              <th>Raised By</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Action</th>
            </thead>
          </table>
        </keep-alive>
          <div v-if="supplier.purchases?.length < 1">
            <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No purchase found</label>
          </div>

        </div>
      </div>
    </section>

    <div>
        <button class="btn btn-default" style="float: right; margin-right: 15px; border: 1px solid #000; margin-bottom: 30px;" @click="this.$router.push('/suppliers')">Close</button>
      </div>
  </div>
</template>

<script>

import Swal from 'sweetalert2'
import Header from '../../components/Header';
import axios from 'axios'
import 'datatables.net-vue3';
import 'datatables.net-bs5';
import "datatables.net-buttons-bs5";
import "datatables.net-responsive-bs5";
import $ from 'jquery';
import 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.print.js';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'ViewSupplierPage',
  components: {
    Header,
  },
  data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      urlServer:process.env.VUE_APP_URL,
      supplier: "",
      supplierId: "",
      displayDocuments: "block",
      name: "",
      natureBusiness: "",
      address: "",
      contact: "",
      phone: "+254",
      email: "",
      qualified: true,
      document: "",
      documents: [],
      documentName: "",
      expiry:"",
      dataTable:  null,
      processedData: []
  }),
  mounted(){
      this.supplierId = this.$route.params.id
      this.setupSupplier(this.supplierId)
  },
  methods:{
      getTotal(arrayL){
        if(arrayL){
          let total =0;
          arrayL.forEach(element => {
              total = total+ element.price
          });
          return total;
        } else{
          return 0;
        }
      },
      preprocessData(rawData) {
        return rawData.map(item => {
          let status =  "";
          
          if(item.status == 'completed'){
            status = '<button class="btn btn-success btn-sm" >Completed</button>'
          } else if(item.status == 'fulfilled'){
            status = '<button class="btn btn-primary btn-sm" >Fulfilled</button>'
          } else if(item.status == 'cancelled'){
            status = '<button class="btn btn-danger btn-sm" >Cancelled</button>'
          } else if(item.status == 'rejected'){
            status = '<button class="btn btn-danger btn-sm" >Rejected</button>'
          } else if(item.status == 'approved'){
            status = '<button class="btn btn-success btn-sm" >Approved</button>'
          } else if(item.status == 'pending'){
            status = '<button class="btn btn-warning btn-sm" >Pending</button>'
          } else {
            status = `<button class="btn btn-default btn-sm" style="text-transform: capitalize; border: 1px solid #312068;" >${item.status}</button>`
          }

          return {
            indexNo:"1",
            createdAt: this.formatDate(item.createdAt),
            purchaseNo: item.purchaseNo,
            department: item.department,
            project: item.project,
            userName: item.User?.name,
            status,
            amount: this.formatMoney(this.getTotal(item.PurchaseProduct)),
            action: `<a class="btn btn-sm btn-primary" href="/purchases/pdf/${item.id}">View</a>`
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#supplierPurchaseTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'createdAt' },
              { data: 'purchaseNo' }, 
              { data: 'department' }, 
              { data: 'project' }, 
              { data: 'userName' }, 
              { data: 'status' }, 
              { data: 'amount' },
              { data: 'action' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Supplier - Purchases\n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Supplier - Purchases\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
      async postUpdate () {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  name: this.name,
                  business: this.natureBusiness,
                  address: this.address,
                  contact: this.contact,
                  phone: this.phone,
                  email: this.email,
                  prequalified: this.qualified,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/suppliers/'+this.supplierId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Supplier Document updated successfully',
                  text: 'Supplier Document updated'
              })
              this.setupSupplier(this.supplierId)
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Supplier not created',
                  text: data.message
              })
          }
      },
      async postUpdateDocument () {
        const formData = new FormData();
        formData.append("name", this.documentName)
        formData.append("expiry", this.expiry)
        formData.append("supplierId", this.supplierId)
        formData.append("file", this.document)

        axios.post(process.env.VUE_APP_URL+'/suppliers/document', 
          formData, 
          {
            headers:{ 
              "Content-Type": "multipart/form-data", 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            if(data.data.status == true){
              window.location.reload();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Supplier Document not created',
                    text: data.data.message
                })
            }
          })
      },
      async setupSupplier(itemId){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/suppliers/'+itemId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.supplier = data.supplier;
            this.supplierId= data.supplier.id;
            this.name =data.supplier.name;
            this.natureBusiness =data.supplier.business;
            this.address =data.supplier.address;
            this.contact =data.supplier.contact;
            this.phone =data.supplier.phone;
            this.email =data.supplier.email;
            this.qualified =data.supplier.prequalified;
            this.documents = data.supplier.documents;

            this.processedData = this.preprocessData(data.supplier.purchases);
            this.initializeDataTable();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Supplier not found. Try again',
                text: data.message
            })
        }
      },
      async deleteSupplier () {
          const requestOptions = {
              method: "GET",
              headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '+this.token 
              },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/suppliers/delete/'+this.supplierId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Suppliers deleted successfully',
                  text: 'Suppliers account deleted'
              })
              this.$router.back()
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Suppliers not deleted',
                  text: data.message
              })
          }
      },
      async deleteSupplierDocument (documentid) {
          const requestOptions = {
              method: "GET",
              headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '+this.token 
              },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/suppliers/document/'+documentid, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Supplier Document deleted successfully',
                  text: 'Supplier Document deleted'
              })
              this.setupSupplier(this.supplierId)
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Supplier Document not deleted',
                  text: data.message
              })
          }
      },
      handleFileChange() {
        this.document = this.$refs.fileDocument.files[0];
      }
      
  }
}
</script>

<style lang="scss" scoped>

$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
</style>