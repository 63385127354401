<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Marked for Return</h3>
  
          <div class="filter-search" style="margin-top: 15px;">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="filter-search" style="margin-top: 20px;">
            <strong>Item List</strong>
            <div id="buttonsContainer"></div>
            <keep-alive>
            <table class="table table-hover display" id="itemsTable">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Reference No.</th>
                  <th>Date Issued</th>
                  <th>Date Returned</th>
                  <th>Product Description</th>
                  <th>Issued To</th>
                  <th>Quantity</th>
                  <th>Project</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>Action</th>
                </tr>
              </thead>
              
            </table>
          </keep-alive>
            <div v-if="activities.length < 1">
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No log found</label>
            </div>

          </div>

        </div>
      </section>


<Modal v-show="isVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Returned Items</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="col-sm-12 mt-1">
          <label for="item-code">Kindly select BPN</label>
          <select v-model="batchId" class="col-7 form-control" id="batches"  v-if="batches.length > 0">
              <option v-for="batch in batches" v-bind:Key="batch.id" :value="batch.id">
                  {{ batch.batchNo }}
              </option>
          </select>
      </div>
      <div class="form-group col-12">
        <label for="item-code">Comment</label>
        <input type="text" class="form-control" v-model="comment">
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <button class="btn-style small" style="border: 1px solid black; float: right;" @click="confirmReturn">Confirm</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
  </template>
</Modal>

    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import Modal from '../../components/Modal.vue';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
  export default {
    name: 'StockTransfers',
    components: {
    Header,
    Modal
},
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        batches: [],
        batchId:"",
        comment: "",
        markedId: "",
        isVisible:false,
        dataTable:  null,
        processedData: []
    }),
    mounted(){
        this.getActivities();
        this.getBatches();

        window.openModal = this.openModal;
        window.remind = this.remind;
    },
    // beforeUnmount() {
    //   if (this.dataTable) {
    //     this.dataTable.destroy();
    //   }
    // },
    methods:{
      closeModal() {
        this.isVisible = false;
      },
      preprocessData(rawData) {
        return rawData.map(item => {
          let referenceNo = "";
          let dateReturned = " - ";
          let issuedTo = "";
          let quantity = " - ";
          let status =  "";
          let action = '<div class="btn-group" role="group"><button type="button" class="btn-sm btn-style small dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button><ul class="dropdown-menu"><li><a class="dropdown-item" href="/inventory/products/'+item.ProductId+'">View Product</a></li>';

          if(item?.reference){
            referenceNo = item.reference
          }
          if(item?.BatchNo){
            referenceNo += " - "+item.BatchNo
          }
          if(item?.assigneCompany) {
            issuedTo=item.assigneCompany
          }
          if(item?.AssignedName) {
            issuedTo = item.AssignedName
          }
          if(item?.PickupQty) {
            quantity =  item.PickupQty
          }
          if(item?.DispatchQty) {
            quantity =  item.DispatchQty
          }
          if(item.status == 'active'){
            status = '<button class="btn btn-warning btn-sm" >Unreturned</button>'
          }
          if(item.status == 'done'){
            status = '<button class="btn btn-success btn-sm" >Returned</button>'
          }
          if(item.status != 'done'){
            action += '<li><a class="dropdown-item" href="#"  onclick="openModal('+item.id+')">Mark as Returned</a></li>'
            action += '<li><a class="dropdown-item" href="#" @click="this.remind('+item.id+')">Remind</a></li>'
          }
          action += '</ul></div>';

          return {
            indexNo:"1",
            referenceNo,
            dateIssued: this.formatDate(item.createdAt),
            dateReturned,
            productName: item.ProductName,
            issuedTo,
            quantity,
            status,
            project: item.project,
            comment: item.comment,
            action,
            ProductId: item.ProductId,
            itemId: item.id,
            statusRow: item.status
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#itemsTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF,
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'referenceNo' },
              { data: 'dateIssued' },
              { data: 'dateReturned' }, 
              { data: 'productName' }, 
              { data: 'issuedTo' }, 
              { data: 'quantity' }, 
              { data: 'project' }, 
              { data: 'status' }, 
              { data: 'comment' },
              { data: 'action', render: this.renderActionButtons }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      renderActionButtons(data, type, row) {
        if (type === 'display') {
          console.log({row})
          console.log(row.statusRow)
          let action = `<div class="btn-group" role="group"><button type="button" class="btn-sm btn-style small dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button><ul class="dropdown-menu"><li><a class="dropdown-item" href="/inventory/products/${row.ProductId}">View Product</a></li>`;
          
          if (row.statusRow != 'done') {
            action += `<li><a class="dropdown-item" href="#" onClick="openModal(${row.itemId})">Mark as Returned</a></li>`;
            action += `<li><a class="dropdown-item" href="#" onclick="remind(${row.itemId})">Remind</a></li>`;
          }
          
          action += '</ul></div>';
          
          return action;
        }
        
        return data;
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Marked for Return \n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Marked for Return\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
        openModal(itemId){
            this.isVisible =true,
            this.markedId = itemId
        },
        async confirmReturn() {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                comment: this.comment,
                batchId: this.batchId,
                markedId: this.markedId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/markedreturn', requestOptions)
          const data = await res.json()
          if(data.status == true){
            this.isVisible = false
            this.comment = ""
            this.batchId = ""
            this.markedId = ""
            this.getActivities()       
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Items not returned. Try again',
                text: data.message
            })
          }
        },
        async getActivities () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/markedreturn', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.activities = data.stocks
                this.processedData = this.preprocessData(data.stocks);
                this.initializeDataTable();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Stock List Marked for Return not found',
                    text: data.message
                })
            }
        },
        async getBatches () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/batchno', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.batches = data.batches
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Try again',
                    text: data.message
                })
            }
        },
        async remind (id) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/markedreturn/remind/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
              Swal.fire({
                icon: 'success',
                title: 'Remind Message Sent',
                text: data.message
              })
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Remind Message Failed',
                  text: data.message
              })
            }
        },
    }
  }
  </script>

<style>
@import 'datatables.net-bs5';
@import 'datatables.net-buttons-bs5';
span{
    color: #000 !important;
}
</style>