<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Reserved Stock</h3>
  
          <div class="filter-search" style="margin-top: 15px;">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
  
          <div class="filter-search" style="margin-top: 20px;">
            <strong>Stock Reserved List</strong>
            <keep-alive>
            <table class="table table-hover" id="reservedTable">
              <thead>
                <th>No.</th>
                <th>Date </th>
                <th>Product Description</th>
                <th>Qty Reserved</th>
                <th>Reserved By</th>
                <th>Warehouse</th>
                <th>Action</th>
              </thead>
            </table>
          </keep-alive>
            <div v-if="activities.length < 1">
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No log found</label>
            </div>

          </div>
        </div>
      </section>

<Modal v-show="isVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Release Reserved Stock Request</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="col-sm-12 mt-1">
          <label for="item-code">Priority</label>
          <select v-model="priority" class="col-7 form-control" id="priorities">
              <option value="urgent">Urgent</option>
              <option value="high">High</option>
              <option value="normal">Normal</option>
          </select>
      </div>
      <div class="form-group col-12">
        <label for="item-code">Quantity to Release</label>
        <input type="number" min="0" class="form-control" v-model="quantity">
      </div>
      <div class="form-group col-12">
        <label for="item-code">Comment</label>
        <input type="text" class="form-control" v-model="comment">
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <button class="btn-style small" style="border: 1px solid black; float: right;" @click="confirmRelease">Confirm</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
  </template>
</Modal>
    </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import Modal from '../../components/Modal.vue'
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
  export default {
    name: 'StockReserveds',
    components: {
      Header,
      Modal
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        isVisible:false,
        priority:"",
        quantity: "",
        comment: "",
        reservedId: "",
        dataTable:  null,
        processedData: []
    }),
    computed:{
        ... mapGetters (["getUsers"]),
        users () { return this.getUsers },
    },
    mounted(){
        this.getActivities(),
        this.fetchUsers()

        window.openModal = this.openModal;
    },
    methods:{
        ... mapActions(['fetchUsers']),  
        
        closeModal() {
          this.isVisible = false;
        }, 
        openModal(itemId){
            this.isVisible =true,
            this.reservedId = itemId
        },
      preprocessData(rawData) {
        return rawData.map(item => {
          return {
            indexNo:"1",
            createdAt: this.formatDate(item.createdAt),
            productName: item.Product.name,
            quantity: this.formatQty(item.quantity),
            userName: item.User?.name,
            warehouse: item.Inventory.Warehouse.name,
            action: '',
            statusRow: item.status,
            itemId: item.id
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#reservedTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'createdAt' },
              { data: 'productName' }, 
              { data: 'quantity' }, 
              { data: 'userName' }, 
              { data: 'warehouse' }, 
              { data: 'action', render: this.renderActionButtons }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      renderActionButtons(data, type, row) {
        if (type === 'display') {
          let action = ''

          if (row.statusRow == 'active') {
            action += `<button class="small btn-style" onClick="openModal(${row.itemId})">Request Release</button>`;
          } else if (row.statusRow == 'pending') {
            action += '<button class="btn btn-sm btn-warning">Pending Approval</button>';
          } else if (row.statusRow == 'completed') {
            action += '<button class="btn btn-sm btn-success">Completed</button>';
          } else if (row.statusRow == 'rejected') {
            action += '<button class="btn btn-sm btn-danger">Rejected</button>';
          }
          
          action += `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-left: 15px;" href="/inventory/stocks/reserveds/${row.itemId}">Activity</a>`;
          
          return action;
        }
        
        return data;
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Stock Reserved\n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Stock Reserved\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
        async getActivities () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/stockreserved', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.activities = data.stocks
                this.processedData = this.preprocessData(data.stocks);
                this.initializeDataTable();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Stock Reserved List not found',
                    text: data.message
                })
            }
        },
        async confirmRelease() {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                quantity: this.quantity,
                priority: this.priority,
                comment: this.comment,
                reservedId: this.reservedId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/stockreserved/release', requestOptions)
          const data = await res.json()
          if(data.status == true){
            Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Stock Release Requested',
                showConfirmButton: false,
                timer: 1500
              })
              window.location.reload()       
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Stock Release not requested',
                text: data.message
            })
          }
        }
    }
  }
  </script>