<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Suppliers</h3>
          <button class="btn-style small pull-right" style="margin-top: -50px;float:right;display: none;" @click="$router.push('/suppliers/add')">Add Supplier</button>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>No. of Suppliers</p>
              <strong class="blue">{{ suppliers.length }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Supplier Name</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Supplier Code</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Nature of Business</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Qualification Status</label>
                      <select class="full" id="handled-by">
                        <option value="Mark John">All</option>
                        <option value="Mark John">Pre-qualified</option>
                        <option value="Mark John">Not Pre-qualified</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small">Filter</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="suppliers-list">
                <strong>Suppliers List</strong>
                <keep-alive>
                <table id="suppliersTable" class="table table-hover">
                    <thead>
                        <th>No.</th>
                        <th>Supplier Name & Code</th>
                        <th>Nature of Business</th>
                        <th>Status</th>
                        <th>No. of POs</th>
                        <th>PO Amount to Date</th>
                        <th>Action</th>
                    </thead>
                </table>
              </keep-alive>
                <div v-if="suppliers.length < 1">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No supplier found</label>
                </div>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
import Header from '../../components/Header';
import 'datatables.net-vue3';
import 'datatables.net-bs5';
import "datatables.net-buttons-bs5";
import "datatables.net-responsive-bs5";
import $ from 'jquery';
import 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.print.js';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {
    name: 'SuppliersPage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        suppliers: [],
        dataTable:  null,
        processedData: []
    }),
    mounted(){
        this.getSuppliers()
    },
    methods:{
      getTotal(arrayL){
        console.log( {arrayL})
        if(arrayL){
          let total =0;
          arrayL.forEach(element => {
            element.PurchaseProduct.forEach( smallEl => {
              total = total+ smallEl.price
            })
          });
          return total;
        } else{
          return 0;
        }
      },
      async getSuppliers () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/suppliers', requestOptions)
            this.suppliers = await res.json()
            this.processedData = this.preprocessData(this.suppliers);
            this.initializeDataTable();
        },
      preprocessData(rawData) {
        return rawData.map(item => {
          let status =  "";
          let amount =  "";
          
          if(item.prequalified){
            status = '<button class="btn btn-primary btn-sm" >Pre-qualified</button>'
          } else {
            status = '<button class="btn btn-danger btn-sm" >Not Pre-qualified</button>'
          }

          if(item.purchases?.length > 0){
            amount = this.formatMoney(this.getTotal(item.purchases))
          } else {
            amount = '0.00'
          }

          return {
            indexNo:"1",
            name: `${item.name} - ${item.code}`,
            business: item.business,
            status,
            noPos: item.purchases?.length,
            amount,
            action: `<a class="btn btn-sm btn-default" style="border: 1px solid black;" href="/suppliers/${item.id}">View Details</a>`
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#suppliersTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'name' },
              { data: 'business' }, 
              { data: 'status' }, 
              { data: 'noPos' }, 
              { data: 'amount' }, 
              { data: 'action' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Suppliers\n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Suppliers\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
    }
  }
  </script>

  <style scoped>
  .suppliers-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>