<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>View Stock - {{ batch?.batchNo }}
            <a class="btn-default btn btn-sm pull-right" target="_blank" :href="`${urlServer}/files/${batch?.document}`" v-if="batch?.document" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 15px; font-weight: 400 !important;font-size: 14px !important; padding: 2px 5px !important;">View Ref Doc</a>
            <button v-if="batchId && batch.isSplit == false" class="btn-default btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 15px;" @click="splitBatchModal()">Split Receipt</button>
            <button v-if="batch.isSplit == true" class="btn-primary btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 15px;">Receipt Splitted</button>
            <button v-if="purchase" class="btn-default btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 15px;"  @click="this.$router.push('/purchases/pdf/'+purchase.id)">View PO</button>
          </h3>
          <div class="row">
            <strong class="col-md-2">1. Delivery Details</strong>
            <strong class="col-md-10" :style="{ 'display': SupplierDisplay }">2. Courier & Supplier Details</strong>
          </div>
          <div>
            <div class="form">
              <div class="row">
                <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Internal Delivery?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="isInternal">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-2" :style="{ 'display': DriverDisplay }">
                  <div class="form-group">
                    <label for="driver">Select Driver </label>
                    <select v-model="driverId" class="full" id="driver"  v-if="users.length > 0">
                        <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Users Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchUsers">Fetch Users</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="suppliers">Select Supplier</label>
                    <select v-model="supplierId" class="full" id="suppliers"  v-if="suppliers.length > 0">
                        <option v-for="supplier in suppliers" v-bind:Key="supplier.id" :value="supplier.id">
                            {{ supplier.name }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Supplier Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchSuppliers">Fetch Suppliers</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="address">Driver's Name</label>
                    <input type="text" v-model="driver" id="address" class="form-control">
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="item-code">Contacts</label>
                    <input type="text" class="form-control" id="item-code" v-model="contacts">
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="item-code">Vehicle Reg No.</label>
                    <input type="text" class="form-control" id="item-code" v-model="vehicle">
                  </div>
                </div>
              </div>
  
              <div class="row">
                <strong class="col-md-5">3. Stock Description</strong>
                <strong class="col-md-7">4. Consignment Details & Documents</strong>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Stock Category</label>
                    <select v-model="nogId" class="full" id="stockCategories"  v-if="nogs.length > 0">
                        <option v-for="nog in nogs" v-bind:Key="nog.id" :value="nog.id">
                            {{ nog.nog }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Stock Category Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchNogs">Fetch Nogs</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="item-code">BPN Tags</label>
                    <select v-model="tagId" class="full" id="suppliers"  v-if="tags.length > 0">
                        <option v-for="tag in tags" v-bind:Key="tag.id" :value="tag.id">
                            {{ tag.tag }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Tag Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchTags">Fetch Tags</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Project / Site</label>
                    <input type="text" class="form-control" id="item-code" v-model="project">
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="item-code">PO No.</label>
                    <select v-model="purchaseId" class="full" id="stockCategories">
                      <option value="null">None</option>
                      <option v-for="purchaseno in purchasenos" v-bind:Key="purchaseno.id" :value="purchaseno.id"> {{ purchaseno.purchaseNo }} </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Select Document</label>
                    <select v-model="document" id="" class="form-control">
                        <option value="Delivery Note">Delivery Note</option>
                        <option value="Invoice">Invoice</option>
                        <option value="Goods Received Receipt">Goods Received Receipt</option>
                        <option value="Written Note">Written Note</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="item-code">Reference 1 #</label>
                    <input type="text" class="form-control" id="item-code" v-model="reference1">
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="item-code">Reference 2 #</label>
                    <input type="text" class="form-control" id="item-code" v-model="reference2">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Upload Document</label>
                    <input type="file" class="form-control" id="item-code" ref="fileDocument" @change="handleFileChange">
                  </div>
                </div>
                <div class="col-md-2">
                    <button class="btn-style small" style="margin-top: 0px" @click="updateBatch">Update Batch</button>
                </div>
  
              </div>
  
            </div>
          </div>
          <div v-if="purchase" :style="{ 'display': displayItems }" style="margin-top:30px;">
            <strong>5. Purchase Order Items</strong>
              <table class="table table-hover">
                <thead>
                    <th>No.</th>
                    <th>Product Description</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Qty Received</th>
                    <th>Price</th>
                    <th>Condition</th>
                    <th>Batch #</th>
                    <th>Warehouse</th>
                    <th>Section</th>
                    <th>Comments</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="purchase.PurchaseProduct?.length > 0">
                    <tr v-for="(item, index) in purchase.PurchaseProduct" v-bind:key="item.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.unit.unit }}</td>
                        <td>{{ item.quantity }}</td>
                        <td><input class="form-control" type="number" min="0" :id="`quantity${item.id}`" /></td>
                        <td><input class="form-control" type="text" :id="`price${item.id}`"/></td>
                        <td>
                            <select  :id="`condition${item.id}`" class="form-control">
                                <option value="New">New</option>
                                <option value="Old">Old</option>
                            </select>
                        </td>
                        <td><input class="form-control" type="text" :id="`batchNo${item.id}`"/></td>
                        <td>
                            <select :id="`warehouseId${item.id}`" class="full form-control" v-on:change="updateWarehouse(item.id)"  v-if="warehouses.length > 0">
                                <option v-for="warehouse in warehouses" v-bind:Key="warehouse.id" :value="warehouse.id">{{ warehouse.name }}</option>
                            </select>
                            <div v-else>
                                <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                                <button class="btn btn-primary btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
                            </div>
                        </td>
                        <td>
                            <select :id="`sectionId${item.id}`" class="full form-control">
                              <option value="">None</option>
                              <option v-for="section in sections" v-bind:Key="section.id" :value="section.id">{{ section.name }}</option>
                            </select>
                        </td>
                        <td><input class="form-control" type="text" :id="`comment${item.id}`"/></td>
                        <td>
                            <button class="btn btn-primary btn-sm" style="border:1px solid black;" :id="`button${item.id}`" @click="completeItem(item.id, item.Product?.id)">Complete</button>
                        </td>
                    </tr>
                </tbody>
                <div v-else>
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No items found</label>
                </div>
            </table>
            
            <div>
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="isVisible =true">Process</button>
              <button class="btn btn-success" style="float: right; margin-right: 10px;" @click="this.$router.push('/transaction')">Save</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="cancelBatchAlert">Cancel</button>
            </div>
          </div>
  
          <div v-else :style="{ 'display': displayItems }" style="margin-top:30px;">
            <strong>5. Add Items</strong>
            <table class="table table-hover">
              <thead>
                <tr>
                    <th>Product Description</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Condition</th>
                    <th>Comments</th>
                    <th>Warehouse</th>
                    <th>Section</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <select v-model="productId" class="full form-control" id="suppliers"  v-if="products.length > 0">
                            <option v-for="product in products" v-bind:Key="product.id" :value="product.id">
                                {{ product.name }}
                            </option>
                        </select>
                        <div v-else>
                            <h5 for="" style="color: red !important;">No Product Found.</h5> 
                            <button class="btn btn-primary btn-sm" @click="this.fetchProducts">Fetch Products</button>
                        </div>
                    </td>
                    <td><input class="form-control" readonly type="text" v-model="itemUnit" /></td>
                    <td><input class="form-control" type="number" min="0" v-model="quantity" /></td>
                    <td><input class="form-control" type="text" v-model="price"/></td>
                    <td>
                        <select v-model="condition" id="" class="form-control">
                            <option value="New">New</option>
                            <option value="Used">Used</option>
                        </select>
                    </td>
                    <td><input class="form-control" type="text" v-model="comment"/></td>
                    <td>
                        <select v-model="warehouseId" class="full form-control" id="warehouses"  v-if="warehouses.length > 0">
                            <option v-for="warehouse in warehouses" v-bind:Key="warehouse.id" :value="warehouse.id">
                                {{ warehouse.name }}
                            </option>
                        </select>
                        <div v-else>
                            <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                            <button class="btn btn-primary btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
                        </div>
                    </td>
                    <td>
                        <select v-model="sectionId" class="full form-control" id="sections">
                          <option value="">None</option>
                            <option v-for="section in sections" v-bind:Key="section.id" :value="section.id">
                                {{ section.name }}
                            </option>
                        </select>
                    </td>
                    <td><button class="btn-style small" style="margin-top: 0px" @click="postItem">Add</button></td>
                </tr>
              </tbody>
              </table>
              <table class="table table-hover">
                <thead>
                    <th>No.</th>
                    <th>Product Description</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Price (Kshs)</th>
                    <th>Condition</th>
                    <th>Location</th>
                    <th>Comments</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" v-bind:key="item.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.unit.unit }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price }}</td>
                        <td>{{ item.condition }}</td>
                        <td>{{ item.batchNo }}</td>
                        <td>{{ item.comment }}</td>
                        <td>
                            <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="removeItem(item.id)">Remove</button>
                        </td>
                    </tr>
                </tbody>
                <div v-else>
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No items found</label>
                </div>
            </table>
  
            <div>
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="isVisible =true">Process</button>
              <button class="btn btn-success" style="float: right; margin-right: 10px;" @click="this.$router.push('/transaction')">Save</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="cancelBatchAlert">Cancel</button>
            </div>
          </div>
        </div>
      </section>
  
  <Modal v-show="isVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Confirm Stock Entry</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="col-md-12">
          <div class="check-button">
          <div class="form-group">
              <label>Entered stock has been physically verified by you?</label>
              <div class="components-button">
          <span class="switcher switcher-1">
              <input type="checkbox" id="switcher-1" v-model="isVerified">
              <label for="switcher-1"></label>
          </span>
              </div>
          </div>
          </div>
      </div>
      <div class="row">
        <label for="item-code">Assigned stock to guardian?</label>
        <div class="col-sm-4">
            <div class="check-button">
            <div class="form-group">
                <div class="components-button">
            <span class="switcher switcher-1">
                <input type="checkbox" id="switcher-1" v-model="isGuardian">
                <label for="switcher-1"></label>
            </span>
                </div>
            </div>
            </div>
        </div>
        <div class="col-sm-8 mt-1" :style="{ 'display': displayGuardian }">
          <select v-model="guardianId" class="col-7 form-control" id="guardians"  v-if="users.length > 0">
              <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                  {{ user.name }}
              </option>
          </select>
          <div v-else>
              <h5 for="" style="color: red !important;">No Guardian Found.</h5> 
              <button class="btn btn-primary btn-sm" @click="this.fetchUsers">Fetch Users</button>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="item-code">BPN Note</label>
        <input type="text" class="form-control" placeholder="Enter note regarding the stock batch" v-model="note">
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <button class="btn-style small" style="border: 1px solid black; float: right;" @click="processBatch">Process</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
  </template>
</Modal>

    </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  import Modal from '../../../components/Modal.vue';
  import axios from 'axios'
  
  export default {
    name: 'SavedBatchPage',
    components: {
      Header,
      Modal,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        items: [],
        sections: [],
        displayItems: "block",
        displayGuardian: "block",
        DriverDisplay: "block",
        SupplierDisplay: "none",
        purchase:"",
        batch: "",
        batchId: "",
        isVisible:false,
        isInternal: true,
        stockCategory: "",
        driverId: "",
        driver: "",
        contacts: "",
        vehicle: "",
        project: "",
        document: "",
        reference1: "",
        reference2: "",
        referenceDocument: "",
        nogId: "",
        tagId: "",
        warehouseId: "",
        sectionId: "",
        supplierId: null,
        purchaseId: null,
        productId: "",
        quantity: "",
        price: "",
        condition: "",
        comment: "",
        batchNo: "",
        itemUnit: "",
        itemSubUnit: "",
        itemNog: "",
        isVerified: true,
        isGuardian: true,
        guardianId: "",
        note: "",
    }),
    computed:{
        ... mapGetters (["getSuppliers", "getUsers", "getNogs", "getPurchases", "getPurchasenos", "getWarehouses", "getTags", "getProducts"]),
        suppliers () { return this.getSuppliers },
        users () { return this.getUsers },
        nogs () { return this.getNogs },
        purchases () { return this.getPurchases },
        purchasenos () { return this.getPurchasenos },
        warehouses () { return this.getWarehouses },
        tags () { return this.getTags },
        products () { return this.getProducts },
    },
    mounted(){
        this.fetchSuppliers(),
        this.fetchUsers(),
        this.fetchPurchases(),
        this.fetchPurchasenos(),
        this.fetchWarehouses(),
        this.fetchTags(),
        this.fetchNogs(),
        this.fetchProducts()
        this.batchId = this.$route.params.id
        this.setupBatch(this.batchId)
    },
    methods:{
        ... mapActions(['fetchSuppliers','fetchUsers', 'fetchNogs', 'fetchPurchases','fetchPurchasenos','fetchWarehouses','fetchTags','fetchProducts']),
        closeModal() {
          this.isVisible = false;
        },
        async processBatch () {
            if(this.batchId == "") {
              Swal.fire({
                  icon: 'error',
                  title: 'Batch not Found',
                  text: "Try again or create a new batch"
              })
              return false;
            }
  
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  batchId: this.batchId,
                  isVerified: this.isVerified,
                  isGuardian: this.isGuardian,
                  guardianId: this.guardianId,
                  note: this.note,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/process', requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                  position: 'top-center',
                  icon: 'success',
                  title: 'Batch Processed',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.$router.push('/transaction')          
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch not Processed',
                    text: data.message
                })
            }
        },
        cancelBatchAlert(){
            Swal.fire({
              title: 'Confirm Batch Cancellation',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, cancel it!'
            }).then((result) => {
              if (result.isConfirmed) {
                this.cancelBatch();
              }
            })
        },
        async cancelBatch () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/cancel/'+this.batchId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                  position: 'top-center',
                  icon: 'success',
                  title: 'Batch Cancelled',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.$router.push('/transaction')          
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch Cancellation Failed',
                    text: data.message
                })
            }
        },
        splitBatchModal(){
          Swal.fire({
            title: 'Confirm',
            text: "Kindly confirm that you want to split receipt.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#312068',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
              this.splitBatch()
            }
          })
        },
        async splitBatch(){
          const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/batches/split/'+this.batchId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Batch Splitted',
                showConfirmButton: false,
                timer: 1500
              })       
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Batch Split Failed',
                  text: data.message
              })
          }
        },
        async updateBatch () {
          let formData = new FormData()
          formData.append("name", this.name)
          formData.append("isInternal", this.isInternal)
          formData.append("driverId", this.driverId)
          formData.append("driver", this.driver)
          formData.append("contacts", this.contacts)
          formData.append("vehicle", this.vehicle)
          formData.append("project", this.project)
          formData.append("document", this.document)
          formData.append("reference1", this.reference1)
          formData.append("reference2", this.reference2)
          formData.append("file", this.referenceDocument)
          formData.append("nogId", this.nogId)
          formData.append("tagId", this.tagId)
          formData.append("supplierId", this.supplierId)
          formData.append("purchaseId", this.purchaseId)
          axios.post(process.env.VUE_APP_URL+'/batches/'+this.batchId, formData, {
            headers:{ 
              "Content-Type": "multipart/form-data", 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            if(data.data.status == true){
              this.batchId = data.data.batch.id
              this.setupBatch(this.batchId)
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch not created',
                    text: data.data.message
                })
            }
          }.bind(this))
        },
        async postItem () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    productId: this.productId,
                    batchId: this.batchId,
                    quantity: this.quantity,
                    price: this.price,
                    condition: this.condition,
                    comment: this.comment,
                    batchNo: this.batchNo,
                    warehouseId: this.warehouseId,
                    sectionId: this.sectionId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/items', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.items = data.items
                this.displayItems = "block"
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch Item not created',
                    text: data.message
                })
            }
        },
        async removeItem (itemId) {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    itemId: itemId,
                    batchId: this.batchId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/items/delete', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.items = data.items
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch Item not found',
                    text: data.message
                })
            }
        },
        async completeItem (itemId, productId) {
            let warehouse = document.getElementById('warehouseId'+itemId) 
            const warehouseId = warehouse.options[warehouse.selectedIndex].value
            let section = document.getElementById('sectionId'+itemId) 
            const sectionId = section.options[section.selectedIndex].value
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    productId: productId,
                    batchId: this.batchId,
                    quantity: document.getElementById('quantity'+itemId).value,
                    price: document.getElementById('price'+itemId).value,
                    condition: document.getElementById('condition'+itemId).value,
                    comment: document.getElementById('comment'+itemId).value,
                    batchNo: document.getElementById('batchNo'+itemId).value,
                    warehouseId,
                    sectionId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/items', requestOptions)
            const data = await res.json()
            if(data.status == true){
              let btn = document.getElementById('button'+itemId)
              btn.setAttribute('disabled','disabled')
              btn.disabled = true
              btn.value = 'Done'
              Swal.fire({
                icon: 'success',
                title: 'Batch Item created',
                text: "Item completed"
            })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch Item not created',
                    text: data.message
                })
            }
        },
        handleFileChange() {
          this.referenceDocument = this.$refs.fileDocument.files[0];
        },
        updateWarehouse(itemId){
          let element = document.getElementById('warehouseId'+itemId) 
          var newValue = element.options[element.selectedIndex].value;
          let item = JSON.parse(JSON.stringify(this.warehouses))
          this.sections = []
          console.log({item})
          const obj = item.find(v => v.id === newValue)
          console.log({obj})
          this.sections = obj.sections
          this.sectionId = ""
        },
        async setupBatch(itemId){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/'+itemId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.batch = data.batch;
                this.batchId = data.batch.id;
                this.isInternal = this.formatBoolean(data.batch.isInternal);
                this.items = data.batch.items;
                this.isInternal = data.batch.isInternal;
                this.stockCategory = data.batch.stockCategory;
                this.driverId= data.batch.driverId;
                this.driver= data.batch.supplierDriver;
                this.contacts= data.batch.supplierContact;
                this.vehicle= data.batch.supplierVehicle;
                this.project= data.batch.project;
                this.document= data.batch.documentType;
                this.reference1= data.batch.reference1;
                this.reference2= data.batch.reference2;
                this.nogId= data.batch.nogId;
                this.tagId= data.batch.tagId;

            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch not found. Try again',
                    text: data.message
                })
            }
        },
        formatBoolean(value){
            if(value){
            return true
            } else{
            return false
            }
        },
        
    },
    watch:{
        productId(newValue){
            let productObj = this.products.find(i => i.id === newValue);
            this.itemUnit = productObj.unit.unit
            this.itemNog = productObj.Category.category
            this.itemSubUnit = "None"
        },
        warehouseId(newValue){
            let obj = this.warehouses.find(i => i.id === newValue);
            this.sections = obj.sections
            this.sectionId = ""
        },
        isGuardian(newValue){
          if(newValue)
            this.displayGuardian = "block"
          else
            this.displayGuardian = "none"
          
        },
        isInternal(newValue) {
          if(newValue){
            this.DriverDisplay = "block"
            this.SupplierDisplay = "none"
          } else{
            this.DriverDisplay = "none"
            this.SupplierDisplay = "block"
          }
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  .form-control{
  border: 1px solid black; 
  border-radius: 5px;
  }

$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>