<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Purchases</h3>
          <button class="btn-style pull-right" style="margin-top: -50px;float:right; display: none;" @click="$router.push('/purchases/add')">Generate PO</button>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Purchase Orders this Month</p>
              <strong class="blue">{{purchasesMonth }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Purchase Orders Pending Approval</p>
              <strong class="blue">{{ purchasePending }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Purchase Orders Fulfilled</p>
              <strong class="blue">{{ purchasefulfilled }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Cancelled Purchase Orders</p>
              <strong class="red">{{ purchaseCancel }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Rejected Purchase Orders</p>
              <strong class="red">{{ purchaseReject }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p class="orange">Value of POs</p>
              <strong class="orange">Kshs {{ purchaseValue }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">PO No.</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Status</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                        <option value="pending">Pending</option>
                        <option value="pending">Approved</option>
                        <option value="pending">Rejected</option>
                        <option value="pending">Fulfilled</option>
                        <option value="pending">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Raised By</label>
                      <select class="full" id="handled-by">
                        <option value="all">Any</option>
                        <option value="pending"></option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Approved By</label>
                      <select class="full" id="handled-by">
                        <option value="all">Any</option>
                        <option value="pending"></option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Amount/Value</label>
                      <input type="number" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Project</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Department</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small">Search</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="purchases-list">
                <strong>Purchase Table</strong>
                <keep-alive>
                <table id="purchasesTable" class="table table-hover">
                    <thead>
                        <th>No.</th>
                        <th>Date</th>
                        <th>PO No.</th>
                        <th>Department</th>
                        <th>Project</th>
                        <th>Raised By</th>
                        <th>Status</th>
                        <th>Exception</th>
                        <th>Fulfilled By</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </thead>
                </table>
              </keep-alive>
                <div v-if="purchases.length < 1">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No purchase found</label>
                </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  export default {
    name: 'HomePurchases',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        purchasesMonth:100,
        purchasePending: 30,
        purchasefulfilled: "34/89",
        purchaseCancel:10,
        purchaseReject: 10,
        purchaseValue: "1,000,000",
        purchases: [],
        dataTable:  null,
        processedData: []
    }),
    mounted(){
        this.getPurchases()
        window.deleteAlert = this.deleteAlert;
    },
    
    methods:{
      preprocessData(rawData) {
        return rawData.map(item => {
          let status =  "";
          let purchaseNo =  "";
          let exception =  "";
          let fulfilledName =  "";

          if(item.batchNo){
            purchaseNo = `${item.purchaseNo} <span style="font-size: 16px;"> - ${item.batchNo}</span>`
          } else {
            purchaseNo = item.purchaseNo
          }

          if(item.exception == 0){
            exception = '<button class="btn btn-success btn-sm" style="margin-left: 5px;">OK</button>'
          } else {
            exception = '<button class="btn btn-danger btn-sm" style="margin-left: 5px;">!</button>'
          }
          

          if(item.fulfilledName){
            fulfilledName = item.fulfilledName
          } else {
            fulfilledName = ' - '
          }
          
          if(item.status == 'completed'){
            status = '<button class="btn btn-success btn-sm" >Completed</button>'
          } else if(item.status == 'fulfilled'){
            status = '<button class="btn btn-primary btn-sm" >Fulfilled</button>'
          } else if(item.status == 'cancelled'){
            status = '<button class="btn btn-danger btn-sm" >Cancelled</button>'
          } else if(item.status == 'rejected'){
            status = '<button class="btn btn-danger btn-sm" >Rejected</button>'
          } else if(item.status == 'approved'){
            status = '<button class="btn btn-success btn-sm" >Approved</button>'
          } else if(item.status == 'pending'){
            status = '<button class="btn btn-warning btn-sm" >Pending</button>'
          } else {
            status = `<button class="btn btn-default btn-sm" style="text-transform: capitalize; border: 1px solid #312068;" >${item.status}</button>`
          }

          return {
            indexNo:"1",
            createdAt: this.formatDate(item.createdAt),
            purchaseNo,
            department: item.department,
            project: item.project,
            raisedName: item.name,
            status,
            exception,
            fulfilledName,
            amount: this.formatMoney(item.amount),
            action:'',
            itemId: item.id,
            statusRow: item.status
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#purchasesTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            destroy: true,
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'createdAt' },
              { data: 'purchaseNo' }, 
              { data: 'department' }, 
              { data: 'project' }, 
              { data: 'raisedName' }, 
              { data: 'status' }, 
              { data: 'exception' },
              { data: 'fulfilledName' }, 
              { data: 'amount' }, 
              { data: 'action', render: this.renderActionButtons }
            ],
            columnDefs: [
              {
                targets: 0,  searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      renderActionButtons(data, type, row) {
        if (type === 'display') {
          let action ='';
          if(row.statusRow == 'pending'){
            action += `<button class="btn btn-sm btn-danger" style="margin-right: 10px;" onClick="deleteAlert(${row.itemId})">Delete</button>`;
          }
          action += `<a class="btn btn-sm btn-default" style="border: 1px solid black;" href="/purchases/pdf/${row.itemId}">View</a>`;
          
          return action;
        }
        
        return data;
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Purchases\n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Purchases\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
      async getPurchases () {
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/purchases', requestOptions)
          const data = await res.json()
            if(data.status == true){
                this.purchases = data.purchases

                console.log("Datatable")
                console.log(this.dataTable);
                this.processedData = this.preprocessData(data.purchases);
                //if(typeof this.dataTable == 'null'){
                  this.dataTable = null;
                  this.initializeDataTable();
                // } else {
                //   this.dataTable.destroy()
                //   this.dataTable = null;
                //   this.initializeDataTable();
                // }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Purchases not found',
                    text: data.message
                })
            }
      },
      deleteAlert(id){
          Swal.fire({
            title: 'Confirm Purchase Order Deletion',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.deletePurchase(id);
            }
          })
      },
      async deletePurchase(id) {
        const requestOptions = {
              method: "DELETE",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token }
          };
          const res = await fetch(process.env.VUE_APP_URL+'/purchases/'+id, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Purchase Order Deleted',
                  text: 'Saved Purchase Order deleted successfully'
              })
            this.fetchPurchases()
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Purchase Order not deleted',
                  text: data.message
              })
          }
      }
    },
    beforeRouteLeave() {
      if (this.dataTable && typeof this.dataTable.destroy === 'function') {
        this.dataTable.destroy();
        this.dataTable = null
      }
    }

  }
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>