<template>
  <div class="inventory">

    <Header :mainHeader="true"/>

    <section id="suppliers-add">
      <div class="container">
        <h3>View Stock - {{ batch?.batchNo }}
          <button class="btn btn-success btn-sm" style="margin-right: 15px;">Completed</button>
          <button class="btn btn-success btn-sm" v-if="batch?.isVerified == true">Verified</button>
          <button class="btn btn-danger btn-sm" v-else>Unverified</button>
          <button class="btn btn-primary btn-sm" v-if="batch?.isSplit == true" style="margin-left: 15px;">Partial</button>
          <button class="btn btn-primary btn-sm" v-if="mergedBatch != 'none'" 
          style="margin-left: 15px;" @click="this.$router.push('/transaction/pdfbatch/'+mergedBatch?.id)">Merged to {{ mergedBatch?.batchNo }}</button>
          
          <button v-if="batch?.Purchase" class="btn-default btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 15px;"  @click="this.$router.push('/purchases/pdf/'+batch?.Purchase?.id)">View PO</button>
          <button v-if="batch?.isSplit != true && batch?.isMerge == false" class="btn-default btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 10px; " @click="showModal">Merge Receipt</button>
          <a class="btn-default btn btn-sm pull-right" target="_blank" :href="`${urlServer}/files/${batch?.document}`" v-if="batch?.document" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 10px; font-weight: 400 !important;font-size: 14px !important; padding: 2px 5px !important;">View Ref Doc</a>
          <button v-if="batchId" class="btn-default btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 15px;"  @click="this.$router.push('/transaction/pdfbatch/'+batch?.id)">BPN Summary</button>
        </h3>
        <div class="row">
          <strong class="col-md-2">1. Delivery Details</strong>
          <strong class="col-md-10">2. Courier & Supplier Details</strong>
        </div>
        <div>
          <div class="form">            
            <div class="row">
                <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Internal Delivery?</label>
                        <div class="components-button">
                          <span class="switcher switcher-1">
                              <input type="checkbox" id="switcher-1" v-model="isInternal">
                              <label for="switcher-1"></label>
                          </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-2" :style="{ 'display': DriverDisplay }">
                  <div class="form-group">
                    <label for="driver">Driver </label>
                    <input type="text" :value="driverObj?.name" id="address" readonly class="form-control">
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="suppliers">Supplier</label>
                    <input type="text" :value="batch?.Supplier?.name" id="address" readonly class="form-control">
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="address">Driver's Name</label>
                    <input type="text" v-model="driver" id="address" readonly class="form-control">
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="item-code">Contacts</label>
                    <input type="text" class="form-control" id="item-code" readonly v-model="contacts">
                  </div>
                </div>
                <div class="col-md-2" :style="{ 'display': SupplierDisplay }">
                  <div class="form-group">
                    <label for="item-code">Vehicle Reg No.</label>
                    <input type="text" class="form-control" id="item-code" readonly v-model="vehicle">
                  </div>
                </div>
              </div>

            <div class="row">
              <strong class="col-md-5">3. Stock Description</strong>
              <strong class="col-md-7">4. Consignment Details & Documents</strong>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Stock Category</label>
                  <input type="text" class="form-control" id="item-code" readonly :value="batch?.Nog?.nog">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="item-code">BPN Tags</label>
                  <input type="text" class="form-control" id="item-code" readonly :value="batch?.Tag?.tag">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Project / Site</label>
                  <input type="text" class="form-control" id="item-code" readonly :value="batch?.project">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="item-code">PO No.</label>
                  <input type="text" class="form-control" id="item-code" readonly :value="batch?.Purchase?.purchaseNo">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Select Document</label>
                  <input type="text" class="form-control" id="item-code" readonly :value="batch?.documentType">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Reference 1 #</label>
                  <input type="text" class="form-control" id="item-code" readonly :value="batch?.reference1">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Reference 2 #</label>
                  <input type="text" class="form-control" id="item-code" readonly :value="batch?.reference2">
                </div>
              </div>

            </div>

          </div>
        </div>

        <div style="margin-top:30px;">
          <strong>5. Add Items</strong>
          <table class="table table-hover">
              <thead>
                  <th>No.</th>
                  <th>Product Description</th>
                  <th>Unit</th>
                  <th>Sub Unit</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Condition</th>
                  <th>Location</th>
                  <th>Comments</th>
                  <th>Action</th>
              </thead>
              <tbody v-if="items?.length > 0">
                  <tr v-for="(item, index) in items" v-bind:key="item.id">
                      <td>{{ (index+1) }}</td>
                      <td>{{ item.Product.name }}</td>
                      <td>{{ item.Product.unit.unit }}</td>
                      <td> - </td>
                      <td>{{ formatQty(item.quantity) }}</td>
                      <td>{{ formatMoney(item.price) }}</td>
                      <td>{{ item.condition }}</td>
                      <td>{{ item.Warehouse?.name }}</td>
                      <td>{{ item.comment }}</td>
                      <td>
                          <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="verifyItem(item.id)" v-if="item.isVerified != true">Verify</button>
                          <button v-else class="btn btn-success btn-sm">Verified</button>
                      </td>
                  </tr>
              </tbody>
              <div v-else>
                <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No items found</label>
              </div>
          </table>

          <div style="margin-bottom: 50px;">
            <button class="btn btn-default" style="float: right; margin-right: 10px;border:1px solid black;" @click="this.$router.push('/transaction')">Close</button>
          </div>
        </div>
      </div>
    </section>

<Modal v-show="isVisible" @close="closeModal" >
  <template v-slot:header>
    <strong>Merge Receipt</strong>
    <p>Kindly confirm that you want to merge receipt.</p>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="col-12 mt-1" >
        <label for="item-code">Merge with BPN</label>
        <select v-model="mergeId" class="col-7 form-control" id="batchnos"  v-if="batchnos.length > 0">
            <option v-for="batch in batchnos" v-bind:Key="batch.id" :value="batch.id">
                {{ batch.batchNo }}
            </option>
        </select>
        <div v-else>
            <h5 for="" style="color: red !important;">No Batch Found.</h5> 
        </div>
      </div>
      <div class="form-group col-12">
        <label for="item-code">Merge Comment</label>
        <input type="text" class="form-control" v-model="comment">
      </div>
    </div>
  </template>

  <template v-slot:footer>
  <button class="btn-style small" style="border: 1px solid black; float: right;" @click="mergeBatch">Confirm</button>
  <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="closeModal">Cancel</button>
  </template>
</Modal>

  </div>
</template>

<script>

import Swal from 'sweetalert2'
import Header from '../../../components/Header';
import Modal from '../../../components/Modal.vue';

export default {
  name: 'ProcessedBatchPage',
  components: {
    Header,
    Modal,
  },
  data: () => ({
    token: JSON.parse(localStorage.getItem('access_token')),
    urlServer:process.env.VUE_APP_URL,
    DriverDisplay: "block",
    SupplierDisplay: "none",
    items: [],
    batchnos:[],
    batchId: "",
    mergeId:"",
    batch: "",
    comment: "",
    isVisible: false,
    isInternal: false,
    stockCategory: "",
    driverId: "",
    driverObj: "",
    mergedBatch:"none",
    driver: "",
    contacts: "",
    vehicle: "",
  }),
  mounted(){        
      this.batchId = this.$route.params.id
      this.setupBatch(this.batchId)
      this.setupBatchNos()
  },
  methods:{
    showModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    async mergeBatch(){
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          body: JSON.stringify({ 
            mergeId: this.mergeId,
            batchId: this.batchId,
            comment: this.comment,
          })
      };
      const res = await fetch(process.env.VUE_APP_URL+'/batches/merge', requestOptions)
      const data = await res.json()
      if(data.status == true){
        this.isVisible = false;
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'Batch Merged',
          showConfirmButton: false,
          timer: 1500
        })
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Batch Item not found',
              text: data.message
          })
      }
    },
      async verifyItem (itemId) {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                itemId: itemId,
                batchId: this.batchId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/batches/verify', requestOptions)
          const data = await res.json()
          if(data.status == true){
            this.items = data.items
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Batch Item not verified',
                  text: data.message
              })
          }
      },
      async setupBatch(itemId){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/batches/'+itemId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.batch = data.batch;
            this.batchId = data.batch.id;
            this.driverObj = data.driver;
            this.mergedBatch = data.mergedBatch;
            this.isInternal = this.formatBoolean(data.batch.isInternal);
            this.items = data.batch.items;
            this.isInternal = data.batch.isInternal;
            this.driverId= data.batch.driverId;
            this.driver= data.batch.supplierDriver;
            this.contacts= data.batch.supplierContact;
            this.vehicle= data.batch.supplierVehicle;
            this.updateIsInternal();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Batch not found. Try again',
                text: data.message
            })
        }
      },
      async setupBatchNos(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/batches/batchno/split', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.batchnos = data.batches;
        }
      },
      formatBoolean(value){
        if(value){
          return true
        } else{
          return false
        }
      },
      updateIsInternal() {
        if(this.isInternal){
          this.DriverDisplay = "block"
          this.SupplierDisplay = "none"
        } else{
          this.DriverDisplay = "none"
          this.SupplierDisplay = "block"
        }
      }
      
  }
}
</script>

<style lang="scss" scoped>

.form-control{
border: 1px solid black; 
border-radius: 5px;
}

$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
</style>