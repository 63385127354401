<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>{{ title }} / Stock Item List </h3>

          <div class="products-list transaction-products-list" style="margin-top: 0px !important;">
            <div class="btn-group" role="group" style="float:right">
              <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black;" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
              <ul class="dropdown-menu">
                <li style="width: 100%;"><a class="dropdown-item" href="#">CSV</a></li>
                <li style="width: 100%;"><a class="dropdown-item" href="#">PDF</a></li>
                <li style="width: 100%;"><a class="dropdown-item" href="#">EXCEL</a></li>
              </ul>
            </div>
            <table class="table table-hover" v-if="activities.length > 0">
              <thead>
                <th>No.</th>
                <th>Product Description</th>
                <th>Category</th>
                <th>Unit</th>
                <th>On Hand</th>
                <th>Qty Reserved</th>
                <th>Qty Available</th>
                <th>SOH Value(Kshs)</th>
                <th>Stock Level Alert</th>
                <th>Warehouse</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(activity, index) in activities" v-bind:key="activity.id">
                  <td>{{ (index+1) }}</td>
                  <td>{{ activity.Product.name }}</td>
                  <td>{{ activity.Product.Category.category }}</td>
                  <td>{{ activity.Product.unit.unit }}</td>
                  <td>{{ (activity.quantity + activity.quantityReserved) }}</td>
                  <td>{{ activity.quantityReserved }}</td>
                  <td>{{ activity.quantity }}</td>
                  <td v-if="activity.Product?.pricePurchase">{{ (activity.quantity+ activity.quantityReserved) * activity.Product.pricePurchase }}</td>
                  <td v-else>-</td>
                  <td>Good</td>
                  <td>{{ activity.Warehouse?.name }}</td>
                  <td>
                    <button class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 10px;" @click="this.$router.push('/warehouses/activity/'+activity.id)">Activity</button>
                    <div class="btn-group" role="group">
                        <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/products/'+activity.Product.id)">View Product</a></li>
                            <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/reserved/'+activity.id)">Reserve Stock</a></li>
                            <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/adjustments/'+activity.id)">Adjust Stock</a></li>
                            <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/transfers/'+activity.id)">Transfer Stock</a></li>
                        </ul>
                    </div>
                </td>
                </tr>
              </tbody>
            </table>

            <div v-else>
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No item found</label>
            </div>

          </div>
  
        </div>
      </section>
  
      <div style="margin-bottom: 50px;">
        <button class="btn btn-default" style="float: right; margin-right: 10px;border:1px solid black;" @click="this.$router.back()">Close</button>
      </div>

    </div>
  </template>
  
  <script>
  
  import Header from '../../components/Header';
  
  export default {
    name: 'ViewWarehouse',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        title: "",
        warehouseId:"",
        type:"",        
        activities: []
    }),
    mounted(){
        this.warehouseId = this.$route.params.id
        this.type = this.$route.params.type
        this.title = this.$route.params.title
        this.setupInventory()
    },
    methods:{
        async setupInventory(){
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                warehouseId: this.warehouseId,
                type: this.type,
                title: this.title,
            })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/inventory/warehouse', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.activities = data.inventory;
          }
        }
        
    }
  }
  </script>