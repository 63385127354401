<template>
  <footer id="footer">
    <div class="container">

    </div>
  </footer>
</template>

<script>
import { defineComponent, h } from 'vue'
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  // Plugin
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale
)

export default defineComponent({
  name: 'LineChart',
  components: {

  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 280
    },
    cssClasses: {
      default: '',
      type: String
    },
  },
  setup(props) {
    const chartData = {
      labels: [
        'Mon',
        'Tue',
        'Wed',
        'Thur',
        'Fri',
        'Sat',
        'Sun'
      ],
      datasets: [
        /* {
           label: 'Data One',
           backgroundColor: '#FFC350',
           data: [40, 39, 10, 40, 39, 80, 40],
           fill: false,
         },*/
        {
          label: 'Data One',
          borderColor: '#7549FF',
          pointBackgroundColor: 'white',
          borderWidth: 2,
          // radius: 0,
          pointBorderColor: '#FFC350',
          backgroundColor: '#FFC350',
          data: [40, 39, 10, 40, 39, 80, 40],
          fill: false,
        },
        {
          label: 'Data Two',
          borderColor: '#4CAF50',
          pointBackgroundColor: 'white',
          borderWidth: 2,
          // radius: 0,
          pointBorderColor: '#FFC350',
          backgroundColor: '#FFC350',
          data: [50, 45, 30, 50, 45, 70, 60],
          fill: false,
        },
      ]
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#92959E'
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: '#92959E'
          },
          grid: {
            display: false
          }
        },
      },

    }

    return () =>
        h(Line, {
          chartData,
          chartOptions,
          chartId: props.chartId,
          width: props.width,
          height: props.height,
          cssClasses: props.cssClasses,
          styles: props.styles,
          plugins: props.plugins
        })
  }
})

</script>
