import { createWebHistory, createRouter } from "vue-router";
import Login from "../views/auth/LoginPage";
import Home from "../views/HomePage";
import AddSupplier from "@/views/supplier/AddSupplier.vue";
import Suppliers from "@/views/supplier/Suppliers.vue";
import ViewSupplier from "@/views/supplier/ViewSupplier.vue";
import Warehouses from "@/views/warehouse/Warehouses.vue";
import AddWarehouse from "@/views/warehouse/AddWarehouse.vue";
import ViewWarehouse from "@/views/warehouse/ViewWarehouse.vue";
import VirtualWarehouse from "@/views/warehouse/VirtualWarehouse.vue";
import ProductWarehouse from "@/views/warehouse/ProductWarehouse.vue";
import LocateWarehouse from "../views/warehouse/LocateWarehouse.vue";
import ProductActivity from "../views/warehouse/ProductActivity.vue";
import AddProduct from "../views/inventory/products/AddProduct.vue";
import ViewProducts from "../views/inventory/products/ViewAllProducts.vue";
import ViewProduct from "../views/inventory/products/ViewProduct.vue";
import EditProduct from "../views/inventory/products/EditProduct.vue";
import ManageStocks from "../views/inventory/ManageStock.vue";
import StockAdjustment from '../views/inventory/StockAdjustment.vue';
import StockAdjustments from '../views/inventory/StockAdjustments.vue';
import StockTransfer from '../views/inventory/StockTransfer.vue';
import StockTransfers from '../views/inventory/StockTransfers.vue';
import StockReserved from '../views/inventory/StockReserved.vue';
import StockReserveds from '../views/inventory/StockReserveds.vue';
import StockReservedActivity from '../views/inventory/StockReservedActivity.vue';
import MarkedReturn from '../views/inventory/MarkedReturn.vue';
import Transaction from "../views/transaction/HomeTransactions.vue";
import AddBatch from "../views/transaction/batch/AddBatch.vue";
import SavedBatch from "../views/transaction/batch/SavedBatch.vue";
import ProcessedBatch from "../views/transaction/batch/ProcessedBatch.vue";
import PdfBatch from "../views/transaction/batch/PdfBatch.vue";
import IncompleteDelivery from "../views/transaction/idn/IncompleteDelivery.vue";
import AddIncompleteDelivery from "../views/transaction/idn/AddIncompleteDelivery.vue";
import PdfIncompleteDelivery from "../views/transaction/idn/IdnPdf.vue";
import SettingsMainPage from "../views/settings/Main.vue";
import SettingsCategory from "../views/settings/Categories.vue";
import SettingsUsers from "../views/settings/Users.vue";
import SettingsUser from "../views/settings/User.vue";
import SettingsApprovals from "../views/settings/ApprovalsHome.vue";
import SettingsApprovalsPending from "../views/settings/ApprovalsPending.vue";
import SettingsRoles from "../views/settings/RolesOverview.vue";
import SettingsRole from "../views/settings/RoleEdit.vue";
import HomePurchase from "../views/purchase/HomePurchases.vue";
import AddPurchase from "../views/purchase/AddPurchase.vue";
import PdfPurchase from "../views/purchase/PdfPurchase.vue";
import Orders from "../views/order/HomeOrders.vue";
import CancelledOrders from "../views/order/CancelledOrders.vue";
import AddPickup from "../views/order/pickup/AddPickup.vue";
import ProcessPickup from "../views/order/pickup/ProcessPickup.vue";
import PickupPdf from "../views/order/pickup/PickupPdf.vue";
import GatePickup from "../views/order/pickup/GatePickup.vue";
import AddDispatch from "../views/order/dispatch/AddDispatch.vue";
import ProcessDispatch from "../views/order/dispatch/ProcessDispatch.vue";
import PdfDispatch from "../views/order/dispatch/PdfDispatch.vue";
import GateDispatch from "../views/order/dispatch/GateDispatch.vue";
import ReportsHome from "../views/reports/ReportsHome.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta: {
            title: 'Pavicon | Login'
        }
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            title: 'Pavicon | Home'
        }
    },
    {
        path: "/inventory/products",
        name: "ViewProducts",
        component: ViewProducts,
        meta: {
            title: 'Pavicon | Products - Inventory',
            keepAlive: false
        }
        
    },
    {
        path: "/inventory/products/add",
        name: "AddProduct",
        component: AddProduct,
        meta: {
            title: 'Pavicon | Add Product - Inventory'
        }
    },
    {
        path: "/inventory/products/:id",
        params:"id",
        name: "ViewProduct",
        component: ViewProduct,
        meta: {
            title: 'Pavicon | View Product - Inventory'
        }
    },
    {
        path: "/inventory/products/edit/:id",
        params:"id",
        name: "EditProduct",
        component: EditProduct,
        meta: {
            title: 'Pavicon | Edit Product - Inventory'
        }
    },
    {
        path: "/inventory/stocks",
        name: "ManageStocks",
        component: ManageStocks,
        meta: {
            title: 'Pavicon | Manage Stocks - Inventory',
            keepAlive: false
        }
        
    },
    {
        path: "/inventory/stocks/adjustments",
        name: "StockAdjustments",
        component: StockAdjustments,
        meta: {
            title: 'Pavicon | Stock Adjustments Log'
        }
        
    },
    {
        path: "/inventory/stocks/adjustments/:id",
        params:"id",
        name: "StockAdjustment",
        component: StockAdjustment,
        meta: {
            title: 'Pavicon | Stock Adjustment'
        }
    },
    {
        path: "/inventory/stocks/transfers",
        name: "StockTransfers",
        component: StockTransfers,
        meta: {
            title: 'Pavicon | Stock Transfers Log'
        }
        
    },
    {
        path: "/inventory/stocks/transfers/:id",
        params:"id",
        name: "StockTransfer",
        component: StockTransfer,
        meta: {
            title: 'Pavicon | Stock Transfer'
        }
    },
    {
        path: "/inventory/stocks/reserveds",
        name: "StockReserveds",
        component: StockReserveds,
        meta: {
            title: 'Pavicon | Stock Reserved Log'
        }
        
    },
    {
        path: "/inventory/stocks/reserveds/:id",
        params:"id",
        name: "StockReservedActivity",
        component: StockReservedActivity,
        meta: {
            title: 'Pavicon | Stock Reserved Activity'
        }
    },
    {
        path: "/inventory/stocks/reserved/:id",
        params:"id",
        name: "StockReserved",
        component: StockReserved,
        meta: {
            title: 'Pavicon | Stock Reserved'
        }
    },
    {
        path: "/inventory/stocks/markedreturn",
        name: "MarkedReturn",
        component: MarkedReturn,
        meta: {
            title: 'Pavicon | Marked for Return'
        }
        
    },
    {
        path: "/transaction",
        name: "Transaction",
        component: Transaction,
        meta: {
            title: 'Pavicon | Transactions'
        }
        
    },
    {
        path: "/transaction/addbatch",
        name: "AddBatch",
        component: AddBatch,
        meta: {
            title: 'Pavicon | Stock/Batch Processing'
        }
    },
    {
        path: "/transaction/savedbatch/:id",
        params:"id",
        name: "SavedBatch",
        component: SavedBatch,
        meta: {
            title: 'Pavicon | Stock/Batch Processing'
        }
    },
    {
        path: "/transaction/processedbatch/:id",
        params:"id",
        name: "ProcessedBatch",
        component: ProcessedBatch,
        meta: {
            title: 'Pavicon | Stock/Batch Processing'
        }
    },
    {
        path: "/transaction/pdfbatch/:id",
        params:"id",
        name: "PdfBatch",
        component: PdfBatch,
        meta: {
            title: 'Pavicon | Stock/Batch Processing'
        }
    },
    {
        path: "/transaction/incompletenotes",
        name: "IncompleteDelivery",
        component: IncompleteDelivery,
        meta: {
            title: 'Pavicon | Incomplete Delivery Notes'
        }
    },
    {
        path: "/transaction/incompletenotes/add",
        name: "AddIncompleteDelivery",
        component: AddIncompleteDelivery,
        meta: {
            title: 'Pavicon | Generate Incomplete Delivery Notes'
        }
    },
    {
        path: "/transaction/incompletenotes/pdf/:id",
        params:"id",
        name: "PdfIncompleteDelivery",
        component: PdfIncompleteDelivery,
        meta: {
            title: 'Pavicon | PDF Incomplete Delivery Notes'
        }
    },
    {
        path: "/suppliers",
        name: "Suppliers",
        component: Suppliers,
        meta: {
            title: 'Pavicon | Suppliers'
        }
        
    },
    {
        path: "/suppliers/add",
        name: "AddSupplier",
        component: AddSupplier,
        meta: {
            title: 'Pavicon | Suppliers Add'
        }
    },
    {
        path: "/suppliers/:id",
        params:"id",
        name: "ViewSupplier",
        component: ViewSupplier,
        meta: {
            title: 'Pavicon | Supplier Details'
        }
        
    },
    {
        path: "/orders",
        name: "Orders",
        component: Orders,
        meta: {
            title: 'Pavicon | Manage Orders'
        }
        
    },
    {
        path: "/orders/cancelled",
        name: "CancelledOrders",
        component: CancelledOrders,
        meta: {
            title: 'Pavicon | Cancelled Orders'
        }
    },
    {
        path: "/orders/pickup/add",
        name: "AddPickup",
        component: AddPickup,
        meta: {
            title: 'Pavicon | Create Pickup Note'
        }
    },
    {
        path: "/orders/pickup/process/:id",
        params:"id",
        name: "ProcessPickup",
        component: ProcessPickup,
        meta: {
            title: 'Pavicon | Process Pickup Note'
        }
    },
    {
        path: "/orders/pickup/pdf/:id",
        params:"id",
        name: "PickupPdf",
        component: PickupPdf,
        meta: {
            title: 'Pavicon | Pickup Note PDF'
        }
    },
    {
        path: "/orders/pickup/gate/:id",
        params:"id",
        name: "GatePickup",
        component: GatePickup,
        meta: {
            title: 'Pavicon | Pickup Note Gate Pass PDF'
        }
    },
    {
        path: "/orders/dispatch/add",
        name: "AddDispatch",
        component: AddDispatch,
        meta: {
            title: 'Pavicon | Create Dispatch Note'
        }
    },
    {
        path: "/orders/dispatch/process/:id",
        params:"id",
        name: "ProcessDispatch",
        component: ProcessDispatch,
        meta: {
            title: 'Pavicon | Process Dispatch Note'
        }
    },
    {
        path: "/orders/dispatch/pdf/:id",
        params:"id",
        name: "PdfDispatch",
        component: PdfDispatch,
        meta: {
            title: 'Pavicon | Dispatch Note PDF'
        }
    },
    {
        path: "/orders/dispatch/gate/:id",
        params:"id",
        name: "GateDispatch",
        component: GateDispatch,
        meta: {
            title: 'Pavicon | Dispatch Note Gate Pass PDF'
        }
    },
    {
        path: "/warehouses",
        name: "Warehouses",
        component: Warehouses,
        meta: {
            title: 'Pavicon | Warehouses'
        }
    },
    {
        path: "/warehouses/add",
        name: "AddWarehouse",
        component: AddWarehouse,
        meta: {
            title: 'Pavicon | Warehouses Add'
        }
    },
    {
        path: "/warehouses/virtual",
        name: "VirtualWarehouse",
        component: VirtualWarehouse,
        meta: {
            title: 'Pavicon | Virtual Warehouses'
        }
    },
    {
        path: "/warehouses/locate",
        name: "LocateWarehouse",
        component: LocateWarehouse,
        meta: {
            title: 'Pavicon | Locate Product - Warehouses'
        }
    },
    {
        path: "/warehouses/activity/:id",
        params:"id",
        name: "ProductActivity",
        component: ProductActivity,
        meta: {
            title: 'Pavicon | Product Activity - Warehouses'
        }
    },
    {
        path: "/warehouses/view/:id",
        params:"id",
        name: "ViewWarehouse",
        component: ViewWarehouse,
        meta: {
            title: 'Pavicon | Warehouse Details'
        }
    },
    {
        path: "/warehouses/product/:id/:type/:title",
        props: true,
        name: "ProductWarehouse",
        component: ProductWarehouse,
        meta: {
            title: 'Pavicon | Warehouse Product'
        }
    },
    {
        path: "/purchases",
        name: "Purchases",
        component: HomePurchase,
        meta: {
            title: 'Pavicon | Purchases'
        }
        
    },
    {
        path: "/purchases/add",
        name: "AddPurchase",
        component: AddPurchase,
        meta: {
            title: 'Pavicon | Purchases Add'
        }
    },
    {
        path: "/purchases/pdf/:id",
        params:"id",
        name: "PdfPurchase",
        component: PdfPurchase,
        meta: {
            title: 'Pavicon | Purchases PDF'
        }
    },
    {
        path: "/settings",
        name: "SettingsMain",
        component: SettingsMainPage,
        meta: {
            title: 'Pavicon | settings'
        }
    },
    {
        path: "/settings/categories",
        name: "SettingsCategory",
        component: SettingsCategory,
        meta:{ title: 'Pavicon | Settings Category'}
    },
    {
        path: "/settings/users",
        name: "SettingsUsers",
        component: SettingsUsers,
        meta:{ title: 'Pavicon | Settings Users'}
    },
    {
        path: "/settings/users/:id",
        params:"id",
        name: "ViewUser",
        component: SettingsUser,
        meta:{ title: 'Pavicon | Settings Users'}
    },
    {
        path: "/settings/approvals",
        name: "SettingsApprovals",
        component: SettingsApprovals,
        meta:{ title: 'Pavicon | Approvals'}
    },
    {
        path: "/settings/approvals/pending",
        name: "SettingsApprovalsPending",
        component: SettingsApprovalsPending,
        meta:{ title: 'Pavicon | Pending Approvals'}
    },
    {
        path: "/settings/roles",
        name: "SettingsRoles",
        component: SettingsRoles,
        meta:{ title: 'Pavicon | Roles'}
    },
    {
        path: "/settings/roles/:id",
        params:"id",
        name: "SettingsRole",
        component: SettingsRole,
        meta:{ title: 'Pavicon | Role'}
    }, 
    {
        path: "/reports",
        name: "ReportsHome",
        component: ReportsHome,
        meta:{ title: 'Reports'}
    },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
    next();
})

export default router;