<template>
    <div class="transaction" :key="componentKey">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Manage Stocks - Stock Levels
            <div class="btn-group" role="group" style="float:right;">
                <button type="button" class="btn btn-style small dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">More Action</button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Low Stock Report</a></li>
                    <li><a class="dropdown-item" href="#">Reorder Report</a></li>
                    <li><a class="dropdown-item" href="#">Stock Variance Report</a></li>
                    <li><a class="dropdown-item" href="#">Stock Adjustment Report</a></li>
                </ul>
            </div>
        </h3>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>No. Items in <br>
                Warehouses</p>
              <strong>2K</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Low Stock <br>
                Level Items</p>
              <strong class="red">18</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Reorder <br>
                Items</p>
              <strong>37</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Items marked  <br>
                for return</p>
              <strong>234</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Value of <br>
                Stock Lost </p>
              <strong>290K</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Stock on Hand <br>
                Value</p>
              <strong class="green">44 M</strong>
            </div>
  
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-4 align-self-end">
                    <label>Product</label>                    
                    <Select2 class="form-control" v-if="products.length > 0" style="width: 100% !important;" v-model="productId" :options="productOptions" :settings="{ width: 'resolve', selectionCssClass: ':all:' }" />
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Product Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchProducts">Fetch Products</button>
                    </div>
                  </div>
                  <div class="col-md-3 align-self-end">
                    <label for="product-category">Product Category</label>
                    <select v-model="categoryId" class="form-control" id="product-category"  v-if="categories.length > 0">
                      <option value="0">All</option>
                        <option v-for="category in categories" v-bind:Key="category.id" :value="category.id">
                            {{ category.category }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Product Category.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchCategories">Fetch Product Categories</button>
                    </div>
                  </div>
                  <div class="col-md-3 align-self-end">
                      <label for="product-sub-category">Product Sub-category</label>
                      <select v-model="subCategoryId" class="form-control" id="product-sub-category">
                          <option value="0">None</option>
                          <option v-for="subcategory in subcategories" v-bind:Key="subcategory.id" :value="subcategory.id">
                              {{ subcategory.category }}
                          </option>
                      </select>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <label for="">Stock Levels</label>
                    <select class="form-control" id="stock-level">
                      <option value="all">Any</option>
                      <option value="over">Over Stocked</option>
                      <option value="normal">Normal</option>
                      <option value="low">Low</option>
                      <option value="out">Out of Stock</option>
                    </select>
                  </div>
                  <div class="col-md-4 align-self-end">
                    <label for="supplierContact">Warehouse</label>
                    <select v-model="warehouseId" class="full form-control" id="warehouses"  v-if="warehouses.length > 0">
                      <option value="0">All</option>
                      <option v-for="warehouse in warehouses" v-bind:Key="warehouse.id" :value="warehouse.id">
                          {{ warehouse.name }} <span v-if="warehouse.isVirtual == true"> - {{ warehouse.description }}</span>
                      </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-primary" style="margin-top: 35px;" type="button" @click="search">Search</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list">
            
            <strong>Stock Item List</strong>
            <keep-alive>
            <table class="table table-hover" id="stocksTable">
              <thead>
                <th>No.</th>
                <th>Product Description</th>
                <th>Category</th>
                <th>Unit</th>
                <th>On Hand</th>
                <th>Qty Reserved</th>
                <th>Qty Available</th>
                <th>SOH Value(Kshs)</th>
                <th>Stock Level</th>
                <th>Warehouse</th>
                <th>Action</th>
              </thead>
              
            </table>
          </keep-alive>
            <div v-if="activities.length < 1">
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No item found</label>
            </div>

          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>

  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import Select2 from 'vue3-select2-component';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {
    name: 'ManageStocks',
    components: {
      Header,
      Select2,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        products: [],
        productId: "0",
        categoryId: "0",
        warehouseId: "0",
        productOptions:[],
        subCategoryId: 0,
        subcategories: [],
        dataTable:  null,
        processedData: [],
        componentKey: 0
    }),
    computed:{
        ... mapGetters (["getCategories", "getWarehouses", "getProductOptions"]),
        categories () { return this.getCategories },
        warehouses () { return this.getWarehouses },
    },
    mounted(){
      this.getActivities()
      this.fetchCategories()
      this.fetchWarehouses()
      this.setupProducts()
    },
    methods:{
      ...mapActions(["fetchCategories", "fetchWarehouses"]),
      async setupProducts(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/products', requestOptions)
        this.products = await res.json()
        this.productOptions = [];
        this.productOptions.push({ id: 0, text: "All"})
        this.products.forEach(product => {
          this.productOptions.push({ id: product.id, text: product.name})
        });
      },
      async getActivities () {
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/inventory/activity', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.activities = data.activities
              this.processedData = this.preprocessData(this.activities);
              if(this.dataTable){
              this.dataTable.destroy();
              }
              this.initializeDataTable();
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Stock Item List not found',
                  text: data.message
              })
          }
      },
      preprocessData(rawData) {
        return rawData.map(item => {
          let sohValue =  "";
          let stockLevel =  "";
          let action = `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 10px;" href="/warehouses/activity/${item.id}">Activity</a><div class="btn-group" role="group">
                        <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="/inventory/products/${item.Product.id}">View Product</a></li>
                            <li><a class="dropdown-item" href="/inventory/stocks/reserved/${item.id}">Reserve Stock</a></li>
                            <li><a class="dropdown-item" href="/inventory/stocks/adjustments/${item.id}">Adjust Stock</a></li>
                            <li><a class="dropdown-item" href="/inventory/stocks/transfers/${item.id}">Transfer Stock</a></li>
                            <li><a class="dropdown-item" href="#" >Add to PO In-tray</a></li>
                            <li><a class="dropdown-item" href="#" >Add to Asset In-tray</a></li>
                        </ul>
                    </div>`;

          if(item.Product?.pricePurchase){
            sohValue = this.formatMoney((item.quantity+ item.quantityReserved) * item.Product.pricePurchase);
          } else {
            sohValue = "-";
          }

          if(item.stock == 'low'){
            stockLevel = 'Low'
          } else if(item.stock == 'out'){
            stockLevel = 'Out of Stock'
          }else if(item.stock == 'normal'){
            stockLevel = 'Normal'
          }else if(item.stock == 'order'){
            stockLevel = 'Re-Order'
          }else if(item.stock == 'over'){
            stockLevel = 'Over Stocked'
          } else{
            stockLevel = ''
          }


          return {
            indexNo:"1",
            productName: item.Product.name,
            productCategory: item.Product?.Category?.category,
            productUnit: item.Product?.unit?.unit,
            onHand: this.formatQty(item.quantity + item.quantityReserved),
            qtyReserved: this.formatQty(item.quantityReserved),
            qtyAvailable: this.formatQty(item.quantity),
            sohValue,
            stockLevel,
            warehouse: item.Warehouse.name,
            action
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#stocksTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF,
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'productName' },
              { data: 'productCategory' },
              { data: 'productUnit' }, 
              { data: 'onHand' }, 
              { data: 'qtyReserved' }, 
              { data: 'qtyAvailable' }, 
              { data: 'sohValue' }, 
              { data: 'stockLevel' }, 
              { data: 'warehouse' }, 
              { data: 'action' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
      customizePDF(doc) {
        doc.content[0].text = `Pavicon (K) Limited | Stock Levels\n As at ${this.formatDate(new Date())}`;

        doc.footer = {
          text: `Pavicon (K) Limited | Stock Levels\n Printed on ${this.formatDate(new Date(), "true")}`,
          alignment: 'center',
        };

        doc.styles.title = {
          color: '#000',
          fontSize: 18,
          bold: true,
        };
      },
      async search(){
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                productId: this.productId,
                categoryId: this.categoryId,
                warehouseId: this.warehouseId,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/inventory/activity/search', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.activities = data.activities
            this.dataTable.clear();
            this.dataTable.rows.add(this.preprocessData(this.activities));
            this.dataTable.draw();
            //this.processedData = this.preprocessData(this.activities);
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: data.message
            })
        }
      },
      isSubCategory(element) {
        return element.id == this.categoryId;
      },
    },
    watch: {
      categoryId() {
        console.log(this.categoryId)
        if(this.categoryId == "0"){
          this.subCategoryId = 0;
          this.subcategories = [];
        } else {
          this.subcategories = []
          const category = this.categories.filter(this.isSubCategory);
          this.subcategories = category[0].subcategories;
        }
      }
    }
  }
  </script>