<template>
    <div class="home">
  
    <Header :mainHeader="true"/>

    <section id="settings-main">
        <div class="container" style="padding: 0px 30px">
                
            <div class="row" id="main-header">
                <div class="col-md-4">
                    <h5>System & User Settings </h5>
                </div>
                <div class="col-md-4">
                    <h5>System Configurations  </h5>
                </div>
                <div class="col-md-4">
                    <h5>Tools</h5>
                </div>
            </div>

            <div class="row panel" >
                <div class="col-md-4">
                    <ul>
                        <li>Company Details</li>
                        <li @click="$router.push('/settings/roles')">Roles & Permissions</li>
                        <li @click="$router.push('/settings/users')">Manage Users</li>
                        <li>Integrations</li>
                        <li>Device Management</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <ul>
                        <li @click="$router.push('/settings/categories')">Category setup </li>
                        <li>Tags Management</li>
                        <li>Document Formats</li>
                        <li>Currencies</li>
                        <li @click="$router.push('/settings/approvals')">Approvals</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <ul>
                        <li>Audit log</li>
                        <li>Alerts and Notifications</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>

import Header from '../../components/Header';
export default {
    name: 'SettingsMainPage',
    components:{
        Header
    }
}
</script>

<style lang="scss" scoped>
ul{
    display: block;
    list-style-type: none;
}
ul > li{
    color: #0c275e;
    list-style: none;
    display: block;
    padding: 3px 0px; 
}
ul li:hover{
    color:#00005C;
    cursor: pointer;
    text-decoration: underline;
}
.panel{
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 0.5px 3px #e4e1e1;
}
#main-header{
    padding: 10px;
    background-color: #e1e1e1;
}
</style>