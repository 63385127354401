<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="warehouse-add">
        <div class="container mt-3">
          <h3>Add Warehouse - {{ title }}</h3>
          <strong>1. Add Details</strong>
          <div class="panel">
            <div class="form">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="warehouses">Warehouse</label>
                    <select v-model="warehouseId" class="form-control" id="warehouses"  v-if="warehouses.length > 0">
                        <option v-for="warehouse in warehouses.filter((value) => value.isVirtual == false)" v-bind:Key="warehouse.id" :value="warehouse.id">
                            {{ warehouse.name }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                        <button class="btn-style btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="product-name">Virtual Warehouse Name</label>
                    <input type="text" class="form-control" id="product-name" v-model="name">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="item-code">Description</label>
                    <input type="text" class="form-control" id="item-code" v-model="description">
                  </div>
                </div>
                <div class="col-md-1">
                    <button class="btn-style small" style="margin-top: 35px" @click="postWarehouse">Create</button>
                </div>
  
              </div>
  
            </div>
          </div>

          <div class="panel">
            <strong>2. Virtual Warehouse List</strong>
            <table class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Warehouse</th>
                <th>Virtual Warehouse</th>
                <th>Description</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(virtual, index) in vwarehouses" v-bind:key="virtual.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ virtual.host.name }}</td>
                    <td>{{ virtual.name }}</td>
                    <td>{{ virtual.description }}</td>
                    <td>
                        <button class="btn btn-danger btn-sm" @click="deleteWarehouse(virtual.id)">Delete</button>
                    </td>
                </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  
  export default {
    name: 'VirtualWarehousePage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        title:"Create Virtual Warehouse",
        vwarehouses:[],
        warehouseId: "",
        displayWarehouses: "none",
        name: "",
        location: "",
        description: "",
    }),
    computed:{
        ... mapGetters (["getWarehouses"]),
        warehouses () { return this.getWarehouses },
    },
    mounted(){
        this.fetchWarehouses()
        this.setupVirtualWarehouses()
    },
    methods:{
      ... mapActions(['fetchWarehouses']),
      async setupVirtualWarehouses(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/warehouses/virtual', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.vwarehouses = data.warehouses;
        }
      },
      async postWarehouse () {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                name: this.name,
                description: this.description,
                warehouseId: this.warehouseId
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/warehouses/virtual', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.vwarehouses = data.warehouses
            this.displayWarehouses = "block"
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Virtual Warehouse not created',
                text: data.message
            })
        }
      },
      async deleteWarehouse (itemId) {
        const requestOptions = {
            method: "GET",
            headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '+this.token 
            },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/warehouses/delete/'+itemId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Warehouse deleted successfully',
                text: 'Warehouse deleted'
            })
            this.setupVirtualWarehouses()
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Warehouse not deleted',
                text: data.message
            })
        }
      },
        
    }
  }
  </script>

  <style lang="scss" scoped>
  .panel{
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
}

$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>