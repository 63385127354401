<template>
<div>
    <div style="position: absolute; width: 100%; height: 2000px; z-index:1;background-color: #f2f2f2;">
        <h3 style="margin-top: 100px; text-align: center;padding: 40px;">Wait as the PDF is being generated</h3>
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    <div class="pdf" id="pdfStart" style="margin: 5px;">
        <div class="row">
            <div class="col-md-7">
                <h3>Incomplete Delivery Note</h3>
            </div>
            <div class="col-md-5">
                <p style="float:right; border: 1px solid #000;">
                    <span style="background-color: gray; color: #000;font-size: 17px;padding:2px ; font-weight: bold;">IDN Status</span>
                    <span style="color: #000; margin-left: 5px;"> {{ note.status }}</span></p>
            </div>

            <div class="col-md-12">
                <h4>{{ note.idnNo }} - {{ batch.batchNo }}</h4>
            </div>
            
            <div class="col-md-7">
                <div style="border: 1px solid #000; width: 400px;">
                    <p style="background-color: gray;padding: 10px; margin:0px; font-weight: bold !important;">Delivery/Courier Details From</p>
                    <p v-if="batch.isInternal == false"  style="margin: 1px 10px; font-weight: bold;">{{ batch.Supplier?.name }}</p>
                    <p v-if="batch.isInternal == false"  style="margin: 1px 10px;">{{ batch.supplierDriver }}</p>
                    <p v-if="batch.isInternal == false"  style="margin: 1px 10px;">{{ batch.supplierContact }}</p>
                    <p v-if="batch.isInternal == false"  style="margin: 1px 10px;">{{ batch.supplierVehicle }}</p>
                    <p v-if="batch.isInternal == true">Internal Delivery</p>
                </div>

            </div>
            <div class="col-md-5">
                <img src="../../../assets/images/barcode.jpg" style="width: 280px; height:120px; float: right;margin-top: -10px;" />
            </div>
            <div class="col-md-5"></div>
            <div class="col-md-7" id="companyDetails">
                <h4>Pavicon (K) Limited</h4>
                <h4>P.O Box 2365 00100 Nairobi</h4>
            </div>
        </div>

        <div class="row" style="border: 1px solid #000;">
            <div class="row" style="background-color: gray;">
                <div class="col-md-6" style="padding: 10px; border-right: 1px solid #f2f2f2;">Stock Description</div>
                <div class="col-md-6" style="padding: 10px;">Consignment Details</div>
            </div>
            <div class="row" style="margin-top: 0px !important; padding-right: 0px !important;">
                <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;border-left: 1px solid #808080;padding: 5px;">Stock Category</div>
                <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">BPN Tags</div>
                <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Project</div>
                <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Purchase Order No.</div>
                <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Reference 1 No.</div>
                <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Reference 2 No.</div>
                <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.Nog?.nog }}</div>
                <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.Tag?.tag }}</div>
                <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.project }}</div>
                <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-if="batch.Purchase">{{  batch.Purchase?.purchaseNo }}</div>
                <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-else>N/A</div>
                <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.reference1 }}</div>
                <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.reference2 }}</div>
            </div>
        </div>

        <div class="row" style="border: 1px solid #000;">
            <p style="background-color: gray;padding: 10px; margin:0px; font-weight: bold !important;">Stock Data Input Details</p>
            <div class="row" style="margin-top: 0px !important; padding-right: 0px !important;">
                <div class="col-md-4" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;border-left: 1px solid #808080;padding: 5px;">Data Entry by:</div>
                <div class="col-md-4" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Date & Time.</div>
                <div class="col-md-4" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Stock Verified By:</div>

                <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.User?.name }}</div>
                <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;">{{  formatDate(batch.createdAt,"true") }}</div>
                <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;" v-if="verifiedBy">{{ verifiedBy?.name }}</div>
                <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;" v-else>-</div>
            </div>
        </div>

        <div class="row" style="border: 1px solid #000;">
            <p style="background-color: gray;padding: 10px; margin:0px; font-weight: bold !important;">Missing Items / Quantities Description</p>
            <table class="table table-bordered">
                <tbody v-if="note?.items?.length > 0">
                    <tr>
                        <th>No.</th>
                        <th>Product</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Comments</th>
                    </tr>
                    <tr v-for="(item, index) in note?.items" v-bind:key="item.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.unit?.unit }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.comment }}</td>
                    </tr>
                </tbody>
                <div v-else>
                    <label for="" class="alert alert-danger" style="margin: 10px auto;  display: block;">No items found</label>
                </div>
            </table>
        </div>
        <div class="row" style="border: 1px solid #000;">
            <p style="background-color: gray;padding: 10px; margin:0px; font-weight: bold !important;">IDN Comments</p>
            <p style="margin-left: 0px;">{{ note.notes }}</p>
        </div>
        <div class="row" style="border: 1px solid #000;">
            <p style="background-color: gray;padding: 10px; margin:0px; font-weight: bold !important;">IDN Settlement Comments</p>
            <p style="margin-left: 0px;">{{ note.settleComment }}</p>
        </div>
        <div class="row" id="footer">
            <div class="col-md-5">
                <p><span style="font-weight: bold;">Note:</span> Printed document is uncontrolled</p>
            </div>
            <div class="col-md-4">
                <p><span style="font-weight: bold;">Printed On:</span> {{ formatDate(new Date(),"true") }}</p>
            </div>
            <div class="col-md-3">
                <p><span style="font-weight: bold;">By:</span> {{ user.name }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <img v-if="note.User?.signature" :src="`${urlServer}/files/${note.User?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">IDN Generated By;</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ note.User?.name }}</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ note.User?.id }}</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(note.createdAt,"true") }}</p>
            </div>
            <div class="col-md-4" v-if="verifiedBy">
                <img v-if="verifiedBy?.signature" :src="`${urlServer}/files/${verifiedBy?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">Stock Verified By;</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ verifiedBy?.name }}</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ verifiedBy?.id }}</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(note.updatedAt,"true") }}</p>
            </div>
            <div class="col-md-4" v-if="settledBy">
                <img v-if="settledBy?.signature" :src="`${urlServer}/files/${settledBy?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">IDN Settled By;</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ settledBy?.name }}</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ settledBy?.id }}</p>
                <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(note.updatedAt,"true") }}</p>
            </div>
        </div>
        <div style="margin-top: 90px !important;">
            <img src="../../../assets/images/enterpriseOne.png" style="height: 80px; width: 300px;"/>
        </div>
    </div>
</div>
  </template>
  
<script>
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
export default {
    name: 'NotePdf',
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        noteId: "",
        note:"",
        user:"",
        batch:"",
        verifiedBy:"",
        settledBy:"",
        guardian:"",
        driver:"",
        updateCount:1,
        spinnerColor:'#ff1d5e'
    }),
    mounted(){
        this.noteId = this.$route.params.id
        this.setupNote(this.noteId)
        this.setupUser()
        setTimeout(()=> { this.exportToPDF() }, 3000)
    },
    methods: {
        exportToPDF() {
            html2pdf(document.getElementById("pdfStart"), { 
                margin: 0.2, 
                filename: new Date().getTime()+"_incomplete_delivery_note.pdf", 
                image:{ type: 'jpeg', quality: 0.98 },
                html2canvas:{ scale: 2,useCORS: true },
                jsPDF:{ unit: 'in',format: 'a4', orientation: 'landscape' }
            });
            setTimeout(()=> { this.$router.back() }, 500)
            
        },
        formatBoolean(value){
            if(value){
            return "YES"
            } else{
            return "NO"
            }
        },
        async setupNote(itemId){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/'+itemId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.noteId = data.note.id;
                this.note = data.note;
                this.batch = data.note.Batch[0];
                this.verifiedBy = data.verifiedBy;
                this.guardian = data.guardian;
                this.driver = data.driver;
                this.settledBy = data.settledBy;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Incomplete Delivery Note not found. Try again',
                    text: data.message
                })
            }
        },
        async setupUser(){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/profile', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.user = data.user;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Incomplete Delivery Note not found. Try again',
                    text: data.message
                })
            }
        },

    }
}
</script>

<style scoped>
p, span, h3, h4{
    color: #000 !important;
}
h3{
    font-size: 33px !important;
}
h4{
    font-weight: bold !important;
}
#companyDetails h4{
    text-align: right;
    font-size: 19px;
}
#footer p{
    font-size: 12px !important;
}
.row{
    margin-top: 20px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002060;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
