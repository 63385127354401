<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Incomplete Delivery Notes <span class="tag">Today</span> 
          <button style="float:right;" class="btn btn-style small" @click="$router.push('/transaction/incompletenotes/add')">Generate IDN</button>
          </h3>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Total IDNs</p>
              <strong class="red">{{ notes?.length }}</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Settled <br>
                IDN</p>
              <strong>5</strong>
            </div>
  
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
              <p>Filter by</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list">
            <div class="btn-group" role="group" style="float:right">
              <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black;" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
              <ul class="dropdown-menu">
                <li style="width: 100%;"><a class="dropdown-item" href="#">CSV</a></li>
                <li style="width: 100%;"><a class="dropdown-item" href="#">PDF</a></li>
                <li style="width: 100%;"><a class="dropdown-item" href="#">EXCEL</a></li>
              </ul>
            </div>
            <table class="table table-hover" v-if="notes.length > 0">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Reference No.</th>
                <th>Stock Category</th>
                <th>Delivery/Courier</th>
                <th>Status</th>
                <th>Handled By</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(note, index) in notes" v-bind:key="note.id">
                  <td>{{ (index+1) }}</td>
                  <td>{{ formatDate(note.createdAt) }}</td>
                  <td>{{ note.Batch[0]?.batchNo }}</td>
                  <td v-if="note.Batch[0]?.nogId">{{ note.Batch[0]?.Nog?.nog }}</td> <td v-else> - </td>
                  <td v-if="note.Batch[0]?.isInternal == false ">{{ note.Batch[0]?.supplierDriver }} - {{ note.Batch[0]?.supplierVehicle }}</td>
                  <td v-else>Internal Delivery</td>
                  <td v-if="note.status == 'settled'"><button class="btn btn-success btn-sm">Settled</button></td>
                  <td v-else><button class="btn btn-sm btn-primary">Open</button></td>
                  <td>{{ note.User?.name }}</td>
                  <td>
                    <button type="button" v-if="note.status != 'settled' " class="btn btn-default btn-sm" style="border: 1px solid black;" @click="isVisible =true,settleModal(note.id)">Settle</button>
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">More</button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" @click="$router.push({name: 'PdfIncompleteDelivery', params:{ id: note.id}})">View IDN Summary</a></li>
                        <li><a class="dropdown-item" target="_blank" v-if="note.settleDocument" :href="`${urlServer}/files/${note.settleDocument}`">View Closure Doc.</a></li>
                        <li><a class="dropdown-item" v-if="note.status != 'settled'" @click="cancelNoteAlert(note.id)">Cancel IDN</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-else>
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No note found</label>
            </div>

          </div>
        </div>
      </section>
     

<Modal v-show="isVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Settle Incomplete Delivery Note</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="form-group col-12">
        <label for="item-code">Upload Supporting Document</label>
        <input type="file" class="form-control" id="item-code" ref="fileDocument" @change="handleFileChange">
      </div>
      <div class="form-group col-12">
        <label for="item-code">Comment</label>
        <input type="text" class="form-control" v-model="settleComment">
      </div>
    </div>
  </template>

  <template v-slot:footer>
  <button class="btn-style small" style="border: 1px solid black; float: right;" @click="uploadSettle">Upload</button>
  <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
  </template>
</Modal>

    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  import Modal from '../../../components/Modal.vue'
  import axios from 'axios'
  import { mapGetters, mapActions } from 'vuex'
    
  export default {
    name: 'IncompleteDeliveryNotes',
    components: {
      Header,
      Modal
    },
    data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      urlServer:process.env.VUE_APP_URL,
      isVisible:false,
      selectedNote:"",
      document:"",
      settleComment:""
    }),
    computed:{
        ... mapGetters (["getNotes",]),
        notes () { return this.getNotes },
    },
    mounted(){
        this.fetchNotes()
    },
    methods:{
      ... mapActions(['fetchNotes']),
      closeModal() {
        this.isVisible = false;
      },
      settleModal(value){
        this.selectedNote = value
      },
      async uploadSettle () {
        const formData = new FormData();
        formData.append("noteId", this.selectedNote)
        formData.append("file", this.document)
        formData.append("comment", this.settleComment)

        axios.post(process.env.VUE_APP_URL+'/incompletenotes/settle', 
          formData, 
          {
            headers:{ 
              "Content-Type": "multipart/form-data", 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            if(data.data.status == true){
              window.location.reload();
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Incomplete Delivery Note not settled',
                  text: data.data.message
              })
            }
          })
      },
      cancelNoteAlert(id){
          Swal.fire({
            title: 'Confirm Incomplete Delivery Note Cancellation',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.cancelNote(id);
            }
          })
      },
      async cancelNote (id) {
          const requestOptions = {
              method: "DELETE",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/'+id, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Incomplete Delivery Note Cancelled',
                showConfirmButton: false,
                timer: 1500
              })
              this.fetchNotes()      
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Incomplete Delivery Note Cancellation Failed',
                  text: data.message
              })
          }
      },
      handleFileChange() {
        this.document = this.$refs.fileDocument.files[0];
      },
    }
  }
  </script>