<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>Generate Purchase Order</h3>
          <strong>1. Add Details</strong>
          <div>
            <div class="form">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-name">Department</label>
                    <input type="text" class="form-control" id="product-name" v-model="department">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Project</label>
                    <input type="text" class="form-control" id="item-code" v-model="project">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="suppliers">Supplier</label>
                    <select v-model="supplierId" class="form-control" id="suppliers"  v-if="suppliers.length > 0">
                        <option v-for="supplier in suppliers" v-bind:Key="supplier.id" :value="supplier.id">
                            {{ supplier.name }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Supplier Found.</h5> 
                        <button class="btn-style btn-sm" @click="this.fetchSuppliers">Fetch Suppliers</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="supplierContact">Contacts</label>
                    <input type="text" class="form-control" id="supplierContact" readonly v-model="supplierContact">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="payments">Terms of Payment</label>
                    <select v-model="paymentId" class="form-control" id="payments"  v-if="payments.length > 0">
                        <option v-for="payment in payments" v-bind:Key="payment.id" :value="payment.id">
                            {{ payment.payment }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Payment Found.</h5> 
                        <button class="btn-style btn-sm" @click="this.fetchPayments">Fetch Payments</button>
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Requisition Number</label>
                    <input type="text" class="form-control" id="item-code">
                  </div>
                </div>
                <div class="col-md-1" :style="{ 'display': displayButton }">
                    <button class="btn-style" style="margin-top: 35px;" @click="postPurchase">Add</button>
                </div>
  
              </div>
  
            </div>
          </div>
          <div :style="{ 'display': displayItems }" style="margin-top:30px">
            <strong>2. Add Products</strong>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="supplierContact">Product Name</label>
                  <select v-model="productId" class="full form-control" id="products"  v-if="products.length > 0">
                    <option v-for="product in products" v-bind:Key="product.id" :value="product.id">
                        {{ product.name }}
                    </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Product Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchProducts">Fetch Products</button>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Product Code</label>
                  <input type="text" readonly v-model="productCode" class="form-control">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Unit</label>
                  <input type="text" readonly v-model="productUnit" class="form-control">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Product Description</label>
                  <input type="text" v-model="productDescription" class="form-control">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Qty Requested</label>
                  <input type="number" min="0" v-model="productRequest" class="form-control">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Price</label>
                  <input type="text" v-model="productPrice" class="form-control">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Action</label><br>
                  <button class="btn btn-default pull-right" style="border:1px solid black;" @click="postProduct">Add</button>
                </div>
              </div>
            </div>

            <strong class="mt-4">3. Product List</strong>
            <table class="table table-hover">
                <thead>
                    <th>No.</th>
                    <th>Product</th>
                    <th>Product Code</th>
                    <th>Product Description</th>
                    <th>Unit</th>
                    <th>Qty Requesting</th>
                    <th>Price</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" v-bind:key="item.id">

                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.code }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.Product.unit.unit }}</td>
                        <td>{{ formatQty(item.quantity) }}</td>
                        <td>{{ formatMoney(item.price) }}</td>
                        <td>
                            <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="deleteProduct(item.id)">Remove</button>
                        </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>{{ formatMoney(total) }}</td>
                      <td></td>
                    </tr>
                </tbody>
                <div v-else>
                  <label for="" class="alert alert-danger" style="margin: 10px auto;  display: block;">No product found</label>
                </div>
            </table>
  
            <div>
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="generatePurchase">Generate</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="$router.push('/purchases')">Close</button>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  
  export default {
    name: 'AddPurchasePage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        displayItems: "none",
        displayButton: "block",
        supplierId: "",
        paymentId:"",
        total: 0,
        supplierContact: "",
        department: "",
        project: "",
        purchaseId:"",
        productId: "",
        productCode: "",
        productDescription: "",
        productUnit: "",
        productQty: "",
        productRequest: "",
        productPrice: "",
        items:[],
    }),
    computed:{
        ... mapGetters (["getPayments", "getSuppliers", "getProducts", "getProductOptions"]),
        payments () { return this.getPayments },
        suppliers () { return this.getSuppliers },
        products () { return this.getProducts },
        productOptions () { return this.getProductOptions },
    },
    methods:{
        ...mapActions(["fetchPayments", "fetchSuppliers", "fetchProducts"]),
        async generatePurchase () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases/generate/'+this.purchaseId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                  position: 'top-center',
                  icon: 'success',
                  title: 'Purchase Generated',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.$router.push('/purchases')          
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Purchase not generated',
                    text: data.message
                })
            }
        },
        async postPurchase () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  department: this.department,
                  project: this.project,
                  supplierId: this.supplierId,
                  paymentId: this.paymentId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.purchaseId = data.purchase.id
                this.displayButton = "none"
                this.displayItems = "block"
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product not created',
                    text: data.message
                })
            }
        },

        async postProduct() {
          const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  description: this.productDescription,
                  quantity: this.productRequest,
                  price: this.productPrice,
                  purchaseId: this.purchaseId,
                  productId: this.productId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases/products', requestOptions)
            const data = await res.json()
            if(data.status == true){
              this.productRequest = ""
              this.productDescription = ""
              this.productPrice = ""
              this.items = data.products
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product not added',
                    text: data.message
                })
            }
        },
        async deleteProduct(id) {
          const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token }
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases/products/'+id+'/'+this.purchaseId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.items = data.products
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Purchase Order Product not deleted',
                    text: data.message
                })
            }
        }
        
    },
    mounted(){
        this.fetchPayments();
        this.fetchSuppliers();
        this.fetchProducts();
    },
    watch:{
        supplierId(newValue){
            let supplierObj = this.suppliers.find(i => i.id === newValue);
            this.supplierContact = supplierObj.address
        },
        productId(newValue){    
          // const productObj = JSON.parse(JSON.stringify(this.products))
          // console.log(newValue)
          // console.log(productObj)
          // let item = productObj.find(i => i.id === newValue);
          // console.log(item)
          // this.productCode = item.code
          // this.productDescription = item.name
          // this.productUnit = item.unit.unit
          let item = this.products.find(i => i.id === newValue);
          this.productCode = item.code
          this.productUnit = item.unit.unit
        },
        items(newValue){
          var sum = 0;
          newValue.forEach(e => {
              sum += e.price;
          });
          this.total=sum
        }
    },
  }
  </script>

<style lang="scss" scoped>
$primary: #312068;
$green: #4CAF50;
$white: #ffffff;
</style>