<template>
  <footer id="footer">
    <div class="container">

    </div>
  </footer>
</template>

<script>
import { defineComponent, h } from 'vue'

import { Bar } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  // Plugin
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default defineComponent({
  name: 'BarChart',
  components: {
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 320
    },
    cssClasses: {
      default: '',
      type: String
    },
  },
  setup(props) {
    const chartData = {
      labels: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#312068',
          data: [40, 20, 12, 39, 10, 40, 39, 87, 40, 20, 12, 11]
        }
      ]
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#92959E'
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: '#92959E'
          },
          grid: {
            display: false
          }
        },
      },
    }

    return () =>
        h(Bar, {
          chartData,
          chartOptions,
          chartId: props.chartId,
          width: props.width,
          height: props.height,
          cssClasses: props.cssClasses,
          styles: props.styles,
          plugins: props.plugins
        })
  }
})


</script>
