<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h3>Approvals</h3>
          <button class="btn-style pull-right" style="margin-top: -50px;float:right;" @click="$router.push('/settings/approvals/pending')">Pending Requests</button>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Pending PO Approvals</p>
              <strong class="blue">0</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Pending Approvals</p>
              <strong class="blue">{{ approvals.length }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Urgent Approvals</p>
              <strong class="red">0</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Pending Requests</p>
              <strong class="green">0</strong>
            </div>
  
          </div>
  
  
          <div class="purchases-list">
                <strong>Pending Approvals</strong>
                <table class="table table-hover" v-if="approvals?.length > 0">
                    <thead>
                        <th>No.</th>
                        <th>Date</th>
                        <th>Priority</th>
                        <th>Request</th>
                        <th>Requested By</th>
                        <th>Status</th>
                        <th>Actioned Date</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <tr v-for="(approval, index) in approvals" v-bind:key="approval.id">
                            <td>{{ index+1 }}</td>
                            <td>{{  formatDate(approval.createdAt,"true") }}</td>
                            <td>
                              <button v-if="approval.priority == 'urgent'" class="btn btn-sm btn-danger">Urgent</button>
                              <button v-else class="btn btn-sm btn-primary">Normal</button>
                            </td>
                            <td v-if="approval.module == 'purchase'">Purchase Order</td>
                            <td v-else-if="approval.module == 'pickup'">Pickup Note</td>
                            <td v-else-if="approval.module == 'dispatch'">Dispatch Note</td>
                            <td v-else>{{ approval.module }}</td>
                            <td>{{  approval.User.name }}</td>
                            <td>
                              <button v-if="approval.type == 'approved'" class="btn btn-sm btn-success">Approved</button>
                              <button v-if="approval.type == 'rejected'" class="btn btn-sm btn-danger">Rejected</button>
                              <button v-if="approval.type == 'pending'" class="btn btn-sm btn-warning">Pending</button>
                            </td>
                            <td v-if="approval.status != 'active'">{{  formatDate(approval.updatedAt,"true") }}</td>
                            <td v-else></td>
                            <td>
                              <button v-if="approval.type =='pending'" class="btn btn-sm btn-primary" @click="processApproval(approval.id)">Approve</button>
                              <button v-if="approval.type =='pending'" class="btn btn-sm btn-danger" style="margin-left: 5px;" @click="rejectApproval(approval.id)">Reject</button>
                              <button class="btn btn-sm btn-default" style="border: 1px solid #000; margin-left: 5px;" @click="showModal(approval)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No approval request found</label>
                </div>
          </div>
        </div>
      </section>

<Modal v-show="isModalVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Request Details</h3>
    <h4>{{ modalTitle }}</h4> 
  </template>

  <template v-slot:body><div id="modalBody"></div></template>

  <template v-slot:footer>
    <button class="btn btn-default" style="border: 1px solid #000" @click="closeModal"> Close</button>
    <button v-if="modalApproval?.type =='pending'" class="btn btn-primary" @click="processApproval(modalApproval?.id)">Approve</button>
    <button v-if="modalApproval?.type =='pending'" class="btn btn-danger" style="margin-left: 5px;" @click="rejectApproval(modalApproval?.id)">Reject</button>
  </template>
</Modal>


    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import { mapGetters, mapActions } from 'vuex'
  import Header from '../../components/Header';
  import Modal from '../../components/Modal.vue'
  import { isProxy, toRaw } from 'vue';

  export default {
    name: 'ApprovalHome',
    components: {
      Header,
      Modal,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        isModalVisible: false,
        modalApproval: "",
        modalTitle:"pending ...",
    }),
    computed:{
        ... mapGetters (["getApprovals"]),
        approvals () { return this.getApprovals },
    },
    methods:{
      ... mapActions(['fetchApprovals']),
      showModal(obj) {
        this.modalApproval = obj;
        this.isModalVisible = true;
        this.modalTitle = this.getModaltitle(this.modalApproval.module)
        document.getElementById("modalBody").innerHTML = this.getModalBody(this.modalApproval)
      },
      getModaltitle(value) {
        switch (value){
          case "purchase": return "Purchase Order"
          case "StockReleased" : return "Release Reserved Stock"
          case "StockAdjustment" : return "Stock Adjustment"
          case "StockTransfer" : return "Stock Transfer"
          default: return ""
        }
      },
      getModalBody(data) {
        let approval=""
        if (isProxy(data)){
          approval = toRaw(data)
        } else { approval = data }
        switch (approval.module){
          case "purchase": return "<table class='table table-bordered'><tr><th>Status</th><td>"+approval.type+"</td></tr><tr><th>Purchase Order Number</th><td>"+approval.purchase?.purchaseNo
            +"</td></tr><tr><th>Department</th><td>"+approval.purchase?.department+"</td></tr><tr><th>Project</th><td>"+approval.purchase?.project
            +"</td></tr><tr><th>Supplier</th><td>"+approval.purchase?.Supplier?.name+" - "+approval.purchase?.Supplier?.phone
            +"</td></tr><tr><th>Purchase Order Created By</th><td>"+approval.purchase?.User?.name+"</td></tr></table><a class='btn btn-sm btn-primary' href=\"/purchases/pdf/"+
            approval.purchase.id+"\">View Purchase Order</a>"

          case "StockReleased" : return "<table class='table table-bordered'><tr><th>Priority</th><td style='text-transform: capitalize;'>"+approval.priority+"</td></tr><tr><th>Status</th><td style='text-transform: capitalize;'>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockReservedActivity?.StockReserved?.Product?.name+" - "+approval.stockReservedActivity?.StockReserved?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockReservedActivity?.StockReserved?.Product?.unit.unit
            +"</td></tr><tr><th>Quantity Requested</th><td>"+approval.stockReservedActivity?.quantityReleased
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockReservedActivity?.note
            +"</td></tr><tr><th>Quantity Reserved</th><td>"+approval.stockReservedActivity?.StockReserved?.quantity
            +"</td></tr><tr><th>Warehouse</th><td>"+approval.stockReservedActivity?.StockReserved?.Inventory?.Warehouse?.name +" - "+approval.stockReservedActivity?.StockReserved?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Quantity Available</th><td>"+approval.stockReservedActivity?.StockReserved?.Inventory.quantity
              +"</td></tr><tr><th>Requested By</th><td>"+approval.stockReservedActivity?.StockReserved?.User?.name+"</td></tr></table>"

          case "StockAdjustment" : return "<table class='table table-bordered'><tr><th>Priority</th><td>"+approval.priority+"</td></tr><tr><th>Status</th><td>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockAdjustment?.Product?.name+" - "+approval.stockAdjustment?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockAdjustment?.Product?.unit.unit
            +"</td></tr><tr><th>Type of Adjustment</th><td>"+approval.stockAdjustment?.type
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockAdjustment?.comment
            +"</td></tr><tr><th>Current Quantity</th><td>"+approval.stockAdjustment?.oldQuantity
            +"</td></tr><tr><th>Quantity Change</th><td>"+approval.stockAdjustment?.quantity
            +"</td></tr><tr><th>New Quantity</th><td>"+approval.stockAdjustment?.newQuantity
            +"</td></tr><tr><th>Warehouse</th><td>"+approval.stockAdjustment?.Inventory?.Warehouse?.name +" - "+approval.stockAdjustment?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Requested By</th><td>"+approval.stockAdjustment?.User?.name+"</td></tr></table>"

          case "StockTransfer" : return "<table class='table table-bordered'><tr><th>Priority</th><td>"+approval.priority+"</td></tr><tr><th>Status</th><td>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockTransfer?.Product?.name+" - "+approval.stockTransfer?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockTransfer?.Product?.unit.unit
            +"</td></tr><tr><th>Quantity</th><td>"+approval.stockTransfer?.quantity
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockTransfer?.comment
            +"</td></tr><tr><th>Old Warehouse</th><td>"+approval.stockTransfer?.Inventory?.Warehouse?.name +" - "+approval.stockTransfer?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Quantity Available</th><td>"+approval.stockTransfer?.Inventory.quantity+"</td></tr><tr><th>New Warehouse</th><td>"+
              approval.stockTransfer?.Warehouse?.name+" - "+approval.stockTransfer?.Warehouse?.description+"</td></tr><tr><th>Requested By</th><td>"+
                approval.stockTransfer?.User?.name+"</td></tr></table>"

          default: return "Details not found. Refresh the page and try again"
        }
      },
      closeModal() {
        this.isModalVisible = false;
      },
      async processApproval (approvalId) {
          this.closeModal()
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  approvalId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/approvals', requestOptions)
          const data = await res.json()
          if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Approval request accepted',
                text: data.message
            })
            this.fetchApprovals();
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Approvals not found',
                  text: data.message
              })
          }
      },
      async rejectApproval (approvalId) {
        this.closeModal()
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  approvalId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/approvals/reject', requestOptions)
          const data = await res.json()
          if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Approval request Rejected',
                text: data.message
            })
            this.fetchApprovals();
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Approvals not found',
                  text: data.message
              })
          }
      },
    },
    mounted(){
        this.fetchApprovals()
    }
  }
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }

  </style>