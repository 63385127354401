<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="warehouse-add">
        <div class="container mt-3">

          <div class="btn-group" role="group" style="float:right; margin-bottom: 10px;">
            <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black;" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
            <ul class="dropdown-menu">
              <li style="width: 100%;"><a class="dropdown-item" href="#">CSV</a></li>
              <li style="width: 100%;"><a class="dropdown-item" href="#" @click="exportToPDF">PDF</a></li>
            </ul>
          </div>
          <div class="panel pdf" id="pdfStart">
          <h3>Stock Activity - {{ productName }} <button class="btn btn-success btn-sm" style="margin-top: -6px !important;  margin-left: 15px !important;">{{ whName }} <span v-if="whVirtual== 1" style="color: #fff !important;">- {{ whDesc }}</span></button></h3>
            <table class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Activity</th>
                <th>Shipped From</th>
                <th>Qty-In</th>
                <th>Qty Adj</th>
                <th>Qty Trans</th>
                <th>Qty Res</th>
                <th>Qty-Out</th>
                <th>BPN</th>
                <th>PN/DN</th>
                <th>Shipped To</th>
                <th>Balance</th>
              </thead>
              <tbody>
                    <tr v-for="(activity, index) in activities" v-bind:key="activity.id">
                        <td>{{ index+1 }}</td>
                        <td>{{  formatDate(activity.createdAt,"true") }}</td>

                        <td style="text-transform: capitalize;" v-if="activity.activity == 'remove'">Stock Issue</td>
                        <td style="text-transform: capitalize;" v-else>{{ activity.activity }}</td>

                        <td v-if="activity.Batch">
                         <span v-if="activity.Batch.isInternal == true && activity.Batch.purchaseId == null">Internal Delivery</span>
                         <span style="display: block !important;" v-if="activity.Batch.Supplier">{{ activity.Batch.Supplier.name }}</span>
                         <span v-if="activity.Batch.Purchase"><a target="_blank" title="View PDF" :href="'/purchases/pdf/'+activity.Batch.Purchase.id">{{ activity.Batch.Purchase.purchaseNo }}</a></span>
                        </td>
                        <td v-else>-</td>

                        <td v-if="activity.activity == 'addition'">{{ activity.changedQuantity }}</td>
                        <td v-else>-</td>

                        <td v-if="activity.StockAdjustment?.length > 0">
                          <span v-if="activity.StockAdjustment?.[0].type == 'Addition'">{{ activity.StockAdjustment?.[0].quantity }} </span>
                          <span v-else>({{ activity.StockAdjustment?.[0].quantity }})</span>
                        </td>
                        <td v-else>-</td>
                        <td v-if="activity.activity == 'StockTransfer'">{{ activity.changedQuantity }}</td>
                        <td v-else>-</td>
                        <td v-if="activity.activity == 'StockReserved'">({{ activity.changedQuantity }})</td>
                        <td v-else-if="activity.activity == 'StockRelease'">{{ activity.changedQuantity }}</td>
                        <td v-else>-</td>
                        <td v-if="activity.activity == 'remove'">({{ activity.changedQuantity }})</td>
                        <td v-else>-</td>
                        <td v-if="activity.Batch"><a target="_blank" title="View PDF" :href="'/transaction/pdfbatch/'+activity.Batch.id">{{ activity.Batch.batchNo }}</a></td>
                        <td v-else>-</td>
                        <td v-if="activity.PickupNote"><a target="_blank" title="View PDF" :href="'/orders/pickup/pdf/'+activity.PickupNote.id">{{ activity.PickupNote.pickupNo }}</a></td>
                        <td v-else-if="activity.DispatchNote"><a target="_blank" title="View PDF" :href="'/orders/dispatch/pdf/'+activity.DispatchNote.id">{{ activity.DispatchNote.dispatchNo }}</a></td>
                        <td v-else>-</td>
                        <td v-if="activity.PickupNote">{{ activity.PickupNote.assigneeCompany }}</td>
                        <td v-else-if="activity.DispatchNote">{{ activity.DispatchNote.assigneeName }}</td>
                        <td v-else>-</td>
                        <td v-if="activity.StockTransfer?.length > 0">{{ activity.quantity }}</td>
                        <td v-else>{{ activity.quantity }}</td>
                    </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      </section>
  
      <div id="pdfLoader" style="position: absolute; width: 100%; height: 2000px; top:0; z-index:1;background-color: #f2f2f2; display: none;">
        <h3 style="margin-top: 100px; text-align: center;padding: 40px;">Wait as the PDF is being generated</h3>
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import html2pdf from "html2pdf.js";
  import exportFromJSON from 'export-from-json';
  
  export default {
    name: 'ProductActivityPage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        productName:"",
        whName:"",
        whVirtual:"",
        whDesc:"",
    }),
    mounted(){
        this.getActivities(this.$route.params.id)
    },
    methods:{
        exportToPDF() {
          document.getElementById("pdfLoader").style.display = "block"
          const collection = document.getElementsByTagName("td");
          for (let i = 0; i < collection.length; i++) {
            collection[i].style.fontSize= "11px";
          }

          html2pdf(document.getElementById("pdfStart"), { 
              margin: 0.2, 
              filename: new Date().getTime()+"_stock_activity.pdf", 
              image:{ type: 'jpeg', quality: 0.98 },
              html2canvas:{ scale: 2,useCORS: true },
              jsPDF:{ unit: 'in',format: 'a4', orientation: 'landscape' }
          });

          for (let i = 0; i < collection.length; i++) {
            collection[i].style.fontSize= "16px";
          }
          
          document.getElementById("pdfLoader").style.display = "none"
        },
        exportData() {
          const data = this.activities;
          const fileName = new Date().getTime()+'_stock_activity';
          const exportType = 'csv';
//{ label: 'Column 3 Subcolumn 2', value: row => row.column3.subcolumn2 }
          const fields = ['No.', 'Date', 'Activity', 'Shipped From', 'Qty-In', 'Qty Adj', 'Qty Trans', 'Qty Res', 'Qty-Out', 'BPN', 'PN/DN', 'Shipped To', 'Balance'];
          console.log({data})
          console.log({fields})
          //exportFromJSON({ data, fileName, exportType, fields });
          exportFromJSON({ data, fileName, exportType });

        },
        
        async getActivities (inventoryId) {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    inventoryId
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/inventory/activity', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.activities = data.activities
                this.productName = data.activities[0].Inventory.Product?.name
                this.whName = data.activities[0].Inventory.Warehouse?.name
                this.whDesc = data.activities[0].Inventory.Warehouse?.description
                this.whVirtual = data.activities[0].Inventory.Warehouse?.isVirtual
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product Stock Activity not found',
                    text: data.message
                })
            }
        },
        
    }
  }
</script>

<style>

.lds-roller {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002060;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>